import React, { useEffect, useState,useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, Typography, Collapse, Grid, IconButton, Button, useMediaQuery, Paper, TextField, CircularProgress,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { SportsSoccer, Star } from '@mui/icons-material'; // Futbol topu ve yıldız simgeleri
import { apiGetFeedRequest } from '../config';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiRequest } from '../config';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom';
import useAuth from '../api/auth';
const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(3),
    },
    listItemIcon: {
        marginRight: 0, // Sağ boşluğu kaldır
    },
}));

const Coupon = ({handleNavigate,setStatus, selectedodddetail,recheck,setRecheck,setselectedodddetail,setOpenSnack,setStatustext, expandedMatchId,reselectodd, setConfirmedodd, confirmedodd, setExpandedMatchId,setBalance,handleChangeIndex }) => {
    const navigate = useNavigate();
    const oddsInputRef = useRef(null);
    const auth = useAuth();
    const [ligler, setLigler] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:1280px)');
    const [backodds, setBackodds] = useState([]);
    const [layodds, setLayodds] = useState([]);
    const [error, setError] = useState();
    const classes = useStyles();
    const [confirm,setConfirm]=useState(false);
    const [loadingcoup,setLoadingcoup]= useState(false);
    const [open, setOpen] = useState({});
    const [betactive, setBetActive] = useState(false);
    const [liabilitytotal, setLiabilityTotal] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
    const roundingRules = [
        { start:1,limit: 2, step: 0.01 },
        { start:2,limit: 3, step: 0.02 },
        { start:3,limit: 4, step: 0.05 },
        { start:4,limit: 6, step: 0.1 },
        { start:6,limit: 10, step: 0.2 },
        { start:10,limit: 20, step: 0.5 },
        { start:20,limit: 30, step: 1.0 },
        { start:30,limit: 50, step: 2.0 },
        { start:50,limit: 100, step: 5.0 },
        { start:100,limit: 1000, step: 10.0 }
      ];
    const handleOpenDialog = (coupon_id) => {
        setCouponToDelete(coupon_id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCouponToDelete(null);
    };

    const handleConfirmDelete = () => {
        deleteCoup(couponToDelete);
        handleCloseDialog();
    };


    const setOdd = (price, item) => {
        setselectedodddetail((prevDetails) => {
            return prevDetails.map((detail) => {
                if (
                    detail.matchId === item.matchId &&
                    detail.type === item.type &&
                    detail.oddid === item.oddid &&
                    detail.marketid === item.marketid &&
                    detail.index === item.index
                ) {
                    return { ...detail, price: price };
                }
                return detail;
            });
        });
    };

    const setStake = (price, item) => {
        setselectedodddetail((prevDetails) => {
            return prevDetails.map((detail) => {
                if (
                    detail.matchId === item.matchId &&
                    detail.type === item.type &&
                    detail.oddid === item.oddid &&
                    detail.marketid === item.marketid &&
                    detail.index === item.index
                ) {
                    return { ...detail, amount: price };
                }
                return detail;
            });
        });
    };
    const deleteodd = (item) => {
        if (item) {
            setselectedodddetail((prevDetails) => {
                return prevDetails.filter((detail) => {
                    // Eğer koşul sağlanırsa bu öğeyi diziye dahil etme
                    return !(
                        detail.matchId === item.matchId &&
                        detail.type === item.type &&
                        detail.oddid === item.oddid &&
                        detail.marketid === item.marketid &&
                        detail.index === item.index
                    );
                });
            });
        } else {
            setselectedodddetail([])
        }

    };
    const deleteCoup = (id) => {
        const token = localStorage.getItem("authToken");
        // Setting loading state on the specific coupon to true
        setConfirmedodd(confirmedodd.map(item => item.coupon_id === id ? { ...item, loading: true } : item));

        let params = { coupon_id: id };
        apiRequest("/api/coupon/cancelcoupon", params, token)
            .then(response => {
                if (response.success === true) {
                    // Update the coupon with new status and loading state
                    const updatedConfirmedOdds = confirmedodd.map(item => {
                        if (item.coupon_id === id) {
                            return { ...item, loading: false, status: 3 }; // Assuming '3' is the status for cancelled
                        }
                        return item;
                    });
                    setOpenSnack(true);
                    setConfirmedodd(updatedConfirmedOdds);
                    setStatus("success");
                    setStatustext( "Teklifiniz Başarıyla İptal Edildi.");
                    setRecheck(recheck+1)
                }else{
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
                }
                const updateBalance = async () => {
      const newBalance = await auth.verify();
      if (newBalance !== null) {
        setBalance(newBalance);
      }
    };
    updateBalance();
            }).catch(error => {
                setOpenSnack(true);
                console.error('Error with API requests:', error);
                setStatus("error");
                setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
                // Reset the loading state if there is an error
                setConfirmedodd(confirmedodd.map(item => item.coupon_id === id ? { ...item, loading: false } : item));
                const updateBalance = async () => {
      const newBalance = await auth.verify();
      if (newBalance !== null) {
        setBalance(newBalance);
      }
    };
    updateBalance();
            });
    }

    
        const fetchCouponStatus = async (couponId) => {
            const token = localStorage.getItem("authToken");
            const payload = { coupon_id: couponId };
    
            try {
                const response = await apiRequest("/api/coupon/getMyCoupons", payload, token);
                if (response && response.length > 0) {
                    return response[0].status;
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            return null;
        };
  
        useEffect(() => {
            const interval = setInterval(async () => {
                let updated = false;
                const updatedConfirmedodd = await Promise.all(
                    confirmedodd.map(async (coupon) => {
                        if (coupon.status == 0 ||  coupon.status == "undefined") {
                            const newStatus = await fetchCouponStatus(coupon.id);
                            if (newStatus !== null  && newStatus !== coupon.status) {
                                updated = true;
                                setRecheck(recheck+1)
                                return { ...coupon, status: String(newStatus) };
                            }
                        }
                        return coupon;
                    })
                );
                if (updated) {
                    setConfirmedodd(updatedConfirmedodd);
                }
            }, 5000);

            return () => clearInterval(interval);
        }, [confirmedodd]);

    useEffect(() => {
        const back = selectedodddetail.filter(item => item.type == "BACK");
        setBackodds(back)
        const lay = selectedodddetail.filter(item => item.type == "LAY");
        setLayodds(lay)
        let liability = 0;
        selectedodddetail.map((item) => {
            const amount = Number(item.amount);
            const price = Number(item.price);

            if (!isNaN(amount) && !isNaN(price)) {  // Geçerli sayılar olduğundan emin ol
                if (item.type === "BACK") {
                    liability += amount;
                } else {
                    liability += (price - 1) * amount;
                }
            } else {
                console.error('Invalid number encountered', { amount, price });
            }

        });
        setLiabilityTotal(liability);
        handleChangeIndex(1);
    }, [selectedodddetail])
    const controlCoup = ()=>{
        const allHaveAmount = selectedodddetail.every(item => item.hasOwnProperty('amount'));
        if (allHaveAmount) {
            const updatedDetails = selectedodddetail.map(item => {
                if (item.hasOwnProperty('price')) {
                    // adjustPrice fonksiyonunu price üzerinde uygula
                    const updatedPrice = adjustPrice(String(item.price));
                    // item nesnesini güncelle
                    return { ...item, price: updatedPrice };
                }
                return item;
            });
            setselectedodddetail(updatedDetails);
        setConfirm(true)
            }  else {
        setError("Tüm Bahisleriniz İçin Tutar Giriniz.");
        setOpenSnack(true);
        setStatus("error");
        setStatustext( "Tüm Bahisleriniz İçin Tutar Giriniz.");
    }
}
    const sendCoupon = async () => {
        const token = localStorage.getItem("authToken");
        const allHaveAmount = selectedodddetail.every(item => item.hasOwnProperty('amount'));
    
        if (allHaveAmount) {
            setLoadingcoup(true)
            const promises = selectedodddetail.map(row => {
                let params = {
                    description: {
                        "event_id": row.matchId,
                        "market_id": row.marketid,
                        "odd_id": row.oddid,
                    },
                    type: row.type,
                    odds: row.price,
                    stake: row.amount
                };
                return apiRequest("/api/coupon/createcoupon", params, token);
            });

            Promise.all(promises).then(responses => {
                const hasError = responses.some(response => {
                    if (response.success === false) {
                        console.error(`Error: Object with id ${response.id} has status false`);
                        return true;
                    }
                    return false;
                });
                if (!hasError) {
                    setConfirmedodd(responses);
                    setselectedodddetail([])
                    setLoadingcoup(false)
                    setOpenSnack(true);
                    setStatus("success");
                    setStatustext( "Bahisiniz oluşturuldu.");
                   
                }else{
                    setConfirmedodd(responses);
                    setselectedodddetail([])
                    setLoadingcoup(false)
                    setOpenSnack(true);
                    setError("Onaylanmayan Bahisler Var");
                    setStatus("error");
                    setStatustext( "Onaylanmayan Bahisler Var");
                }
                const updateBalance = async () => {
      const newBalance = await auth.verify();
      if (newBalance !== null) {
        setBalance(newBalance);
      }
    };
    updateBalance();
                setRecheck(recheck+1)

            }).catch(error => {
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setError("Bir Sorun Oluştu.");
                setStatus("error");
                setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
                const updateBalance = async () => {
      const newBalance = await auth.verify();
      if (newBalance !== null) {
        setBalance(newBalance);
      }
    };
    updateBalance();
            });
        } else {
            setError("Tüm Bahisleriniz İçin Tutar Giriniz.");
            setOpenSnack(true);
            setStatus("error");
            setStatustext( "Tüm Bahisleriniz İçin Tutar Giriniz.");
        }
    };
    const adjustPrice = (price) => {
        const numericPrice = Number(price.replace(/,/g, '.'));
        if (numericPrice > 1 && numericPrice <= 2) {
          // 1-2 arasında virgül olarak ,01 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.01) * 0.01)).toFixed(2);
      } else if (numericPrice > 2 && numericPrice <= 3) {
          // 2-3 arasında virgül olarak ,02 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.02) * 0.02)).toFixed(2);
      } else if (numericPrice > 3 && numericPrice <= 4) {
          // 3-4 arasında virgül olarak ,05 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.05) * 0.05)).toFixed(2);
      } else if (numericPrice > 4 && numericPrice <= 6) {
          // 4-6 arasında virgül olarak ,1 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.1) * 0.1)).toFixed(2);
      } else if (numericPrice > 6 && numericPrice <= 10) {
          // 6-10 arasında virgül olarak ,2 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.2) * 0.2)).toFixed(2);
      } else if (numericPrice > 10 && numericPrice <= 20) {
          // 10-20 arasında virgül olarak ,5 olabilir
          return (Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.5) * 0.5)).toFixed(2);
      } else if (numericPrice > 20 && numericPrice <= 30) {
          // 20-30 arasında tam sayı olacak
          return (Math.round(numericPrice)).toFixed(2);
      } else if (numericPrice > 30 && numericPrice <= 50) {
          // 30-50 arasında çift sayı
          return (2 * Math.round(numericPrice / 2)).toFixed(2);
      } else if (numericPrice > 50 && numericPrice <= 100) {
          // 50-100 arasında 5'erli tam sayı
          return (5 * Math.round(numericPrice / 5).toFixed(2));
      } else if (numericPrice > 100 && numericPrice <= 1000) {
          // 100-1000 arasında 10'ar lı tam sayı
          return (10 * Math.round(numericPrice / 10)).toFixed(2);
      }
      
      return numericPrice; 
    };
    const getsteprule=(value)=>{
      for (let rule of roundingRules) {
        if (value >= rule.start && value < rule.limit) {
          return rule.step;
        }
      }
      return null;
    }
    
    const handleIncrement = (item) => {

        setselectedodddetail((prevDetails) => {
            return prevDetails.map((detail) => {
                if (
                    detail.matchId === item.matchId &&
                    detail.type === item.type &&
                    detail.oddid === item.oddid &&
                    detail.marketid === item.marketid &&
                    detail.index === item.index
                ) {
                    return { ...detail, price: parseFloat(adjustPrice(String((Number(item.price)+Number(getsteprule(item.price)))))).toFixed(2) };
                }
                return detail;
            });
        });
      
    };
    
    const handleDecrement = (item) => {

        setselectedodddetail((prevDetails) => {
            return prevDetails.map((detail) => {
                if (
                    detail.matchId === item.matchId &&
                    detail.type === item.type &&
                    detail.oddid === item.oddid &&
                    detail.marketid === item.marketid &&
                    detail.index === item.index
                ) {
                    return { ...detail, price: parseFloat(adjustPrice(String((Number(item.price)-Number(getsteprule(item.price)))))).toFixed(2) };
                }
                return detail;
            });
        });
      
    };
    return (
        <>
            <Grid sx={{ width: "100%" }}>
                {selectedodddetail.length == 0 ? (
                    <Paper sx={{ textAlign: "center", padding: "25px" }}>
                        <Typography>Kuponunuz Boş</Typography>
                    </Paper>
                ) : (
                    <Paper>
                        {backodds.length > 0 &&
                            <>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <Grid container sx={{ paddingLeft: "5px", paddingRight: "5px", color: "#a5a5a5" }}>
                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ bgcolor: '#a6d8ff', color: "#000", textAlign: "center", borderRadius: "5px", marginRight: "50%" }}>
                                                {"BACK"}</Typography>
                                        </Grid>
                                        <Grid item xs={3.75} sm={3.75} md={3.75} lg={3.75} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Oran"}</Typography>
                                        </Grid>
                                        <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Tutar"}</Typography>
                                        </Grid>
                                        <Grid item xs={2.75} sm={2.75} md={2.75} lg={2.75} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Kâr"}</Typography>
                                        </Grid>
                                    </Grid>
                                    {backodds.map((item, index) => {
                                        return (

                                            <Grid container key={item.marketid+"a2"+item.oddid} sx={{ borderBottom: "solid 1px #ff6c00", borderLeft: "solid #a6d8ff", paddingTop: "5px", paddingBottom: "5px", marginTop: "5px", paddingLeft: "5px", paddingRight: "5px", alignItems: "center" }}>
                                                <Grid item xs={3} sm={3} md={3} lg={3} >
                                                    <Typography sx={{ fontSize: "0.7rem", fontWeight: "600" }}>{item.marketname}</Typography><Typography sx={{ fontSize: "1rem" }}>{item.oddname}</Typography>
                                                </Grid>
                                                <Grid item xs={3.75} sm={3.75} md={3.75} lg={3.75} >
                                             
                                                              <Grid container alignItems="center" spacing={1}>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
              <Button variant="outlined" onClick={()=>{handleDecrement(item)}} sx={{padding:"6.5px 6px",minWidth:"20px",width:"100%"}} ><RemoveIcon /></Button>
            </Grid>
            <Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5}>
              <TextField
                size="small"
                value={item.price}
                ref={oddsInputRef}
                key={"oddinput"}
                onFocus={() => setBetActive(true)}
                onChange={(e) => {
                    setOdd(e.target.value, item);
                    setConfirm(false)
                }}
                onBlur={(e) => {
                    const adjustedValue = adjustPrice(e.target.value);
                    setOdd(adjustedValue, item);
                    setBetActive(false)
                }}
                inputProps={{  style: { textAlign: 'center',padding:"8.5px 3px" } }}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
              <Button variant="outlined" onClick={()=>{handleIncrement(item)}} sx={{padding:"6.5px 6px",minWidth:"20px",width:"100%"}} ><AddIcon /></Button>
            </Grid>
          </Grid>
                                                </Grid>
                                                <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} >
                                                    <TextField
                                                        type="text"
                                                        value={item?.amount}
                                                        onChange={(e) => {setStake(e.target.value, item),setConfirm(false)}}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: "90%" }}
                                                        InputProps={{
                                                            style: { textAlign: 'center',padding:"0px 3px"  }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} >
                                                    <Typography sx={{ fontSize: "1rem" }}>{item?.price && item?.amount ? ((item?.price - 1) * item?.amount).toFixed(2) : 0}</Typography>
                                                </Grid>
                                                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} >
                                                <IconButton aria-label="delete" sx={{ color: "#d5d5d5" }} onClick={() => deleteodd(item)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Paper>
                            </>
                        }
                        {layodds.length > 0 &&
                            <>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <Grid container sx={{ paddingLeft: "5px", paddingRight: "5px", color: "#a5a5a5" }}>
                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ bgcolor: '#fac9d1', color: "#000", textAlign: "center", borderRadius: "5px", marginRight: "50%" }}>
                                                {"LAY"}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Oran"}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Tutar"}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ borderBottom: "solid 1.5px", paddingTop: "5px", paddingBottom: "5px" }} >
                                            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Risk"}</Typography>
                                        </Grid>
                                    </Grid>
                                    {layodds.map((item, index) => {
                                        return (

                                            <Grid container key={item.marketid+"a3"+item.oddid} sx={{ borderBottom: "solid 1px #ff6c00", borderLeft: "solid #fac9d1", paddingTop: "5px", paddingBottom: "5px", marginTop: "5px", paddingLeft: "5px", paddingRight: "5px", alignItems: "center" }}>
                                                  <Grid item xs={3} sm={3} md={3} lg={3} >
                                                    <Typography sx={{ fontSize: "0.7rem", fontWeight: "600" }}>{item.marketname}</Typography><Typography sx={{ fontSize: "1rem" }}>{item.oddname}</Typography>
                                                </Grid>
                                                <Grid item xs={3.75} sm={3.75} md={3.75} lg={3.75} >
                                             
                                                              <Grid container alignItems="center" spacing={1}>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
              <Button variant="outlined" onClick={()=>{handleDecrement(item)}} sx={{padding:"6.5px 6px",minWidth:"20px",width:"100%"}} ><RemoveIcon /></Button>
            </Grid>
            <Grid item xs={6.5} sm={6.5} md={6.5} lg={6.5}>
              <TextField
                size="small"
                value={item.price}
                ref={oddsInputRef}
                key={"oddinput"}
                onFocus={() => setBetActive(true)}
                onChange={(e) => {
                    setOdd(e.target.value, item);
                    setConfirm(false)
                }}
                onBlur={(e) => {
                    const adjustedValue = adjustPrice(e.target.value);
                    setOdd(adjustedValue, item);
                    setBetActive(false)
                }}
                inputProps={{  style: { textAlign: 'center',padding:"8.5px 3px" } }}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
              <Button variant="outlined" onClick={()=>{handleIncrement(item)}} sx={{padding:"6.5px 6px",minWidth:"20px",width:"100%"}} ><AddIcon /></Button>
            </Grid>
          </Grid>
                                                </Grid>
                                                <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} >
                                                    <TextField
                                                        type="text"
                                                        value={item?.amount}
                                                        onChange={(e) => {setStake(e.target.value, item),setConfirm(false)}}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: "90%" }}
                                                        InputProps={{
                                                            style: { textAlign: 'center',padding:"0px 3px"  }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} >
                                                    <Typography sx={{ fontSize: "1rem" }}>{item?.price && item?.amount ? ((item?.price - 1) * item?.amount).toFixed(2) : 0}</Typography>
                                                </Grid>
                                                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} >
                                                <IconButton aria-label="delete" sx={{ color: "#d5d5d5" }} onClick={() => deleteodd(item)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Paper>
                            </>
                        }
                        <Grid>
                            <Typography sx={{ fontSize: "1rem", textAlign: "end", paddingRight: "15px" }}>{"Toplam Risk:"}{liabilitytotal.toFixed(2)}</Typography>
                        </Grid>
                        <Grid container sx={{ paddingRight: '10px', paddingLeft: "10px", paddingBottom: "15px", paddingTop: "10px", alignItems: 'center' }}>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Button
                                    variant="contained"
                                    sx={{ padding: '5px', bgcolor: '#d5d5d5', color: '#000' }}
                                    onClick={() => deleteodd()}
                                    startIcon={<DeleteIcon />}
                                >
                                    Tümünü Sil
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    disabled={loadingcoup?true:false}
                                    onClick={()=>{confirm?sendCoupon():controlCoup()}}
                                    sx={{ padding: '5px', bgcolor: '#ff6c00', color: '#000' }}
                                >{loadingcoup ?
                                    <CircularProgress size={24} sx={{color:"#000"}} />
                                    :
                                    confirm?"Bahisi Onayla":"Bahis Yap"}
                                </Button>
                            </Grid>
                        </Grid>

                    </Paper>
                )}
            </Grid>
            {!confirmedodd.length == [] &&
                <>
                    <Grid sx={{ width: "100%", padding: "10px", marginTop: "20px", backgroundColor: "#272727", borderBottom: "solid 2px" }}>
                        <Typography sx={{ fontSize: "0.875rem", fontWeight: "500", textAlign: "center" }}>{"DURUM"}</Typography>
                    </Grid>
                    <Paper sx={{ overflow: "hidden" }}>
                        <Grid container sx={{ paddingLeft: "5px", paddingRight: "5px", color: "#a5a5a5", borderBottom: "solid 1.5px", }}>
                            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "7px" }} >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Market"}</Typography>
                            </Grid>
                            <Grid item xs={2.25} sm={2.25} md={2.25} lg={2.25} sx={{ paddingTop: "5px", paddingBottom: "5px" }} >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Tip"}</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ paddingTop: "5px", paddingBottom: "5px" }} >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Oran"}</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ paddingTop: "5px", paddingBottom: "5px" }} >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Risk"}</Typography>
                            </Grid>
                            <Grid item xs={2.25} sm={2.25} md={2.25} lg={2.25} sx={{ paddingTop: "5px", paddingBottom: "5px" }} >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{"Durumu"}</Typography>
                            </Grid>
                        </Grid>
                        {confirmedodd.map((item) => (
                            <>
                            {item.success==true?
                            <Grid key={item?.betfair_id+"a1"+item?.odd_id}>
                                <Grid container sx={{ borderBottom: "solid 1px", alignItems: "center", paddingTop: "10px", paddingBottom: "5px" }}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} sx={{ paddingLeft: "10px" }}>
                                        <Typography sx={{ fontSize: "0.7rem" }}>{item?.market_name}</Typography><Typography>{item?.odd_name}</Typography>
                                    </Grid>
                                    <Grid item xs={2.25} sm={2.25} md={2.25} lg={2.25} >
                                        {item?.type == 0 ? <Typography sx={{ bgcolor: '#a6d8ff', color: "#000", textAlign: "center", borderRadius: "5px", width: "55px" }}>
                                            {"BACK"}</Typography> : <Typography sx={{ bgcolor: '#fac9d1', color: "#000", textAlign: "center", borderRadius: "5px", width: "55px" }}>
                                            {"LAY"}</Typography>}
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        {item?.odds}
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2}  >
                                        {item?.type == 0 ? (parseFloat(item?.stake)).toFixed(2) : ((item?.odds - 1) * item?.stake).toFixed(2)}
                                    </Grid>
                                    <Grid item xs={2.25} sm={2.25} md={2.25} lg={2.25} >
                                        {!item?.loading == 1 ?

                                            <>
                                                {item?.status == 0 ?
                                                    <>
                                                        <Typography sx={{ bgcolor: '#ff6c00', color: "#FFF", textAlign: "center", borderRadius: "5px" }}> {"Teklifte"}</Typography>
                                                        <Grid container sx={{ alignContent: "center" }}>
                                                            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ marginRight: "18px" }} >
                                                                <IconButton aria-label="delete" size="large" onClick={() => { handleOpenDialog(item?.coupon_id) }} sx={{ padding: "5px",color:"red" }}>
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={3} sm={3} md={3} lg={3} >
                                                                <IconButton aria-label="return" size="large" onClick={()=>{reselectodd(item?.event_id,item?.market_id,item?.odd_id,item?.type=="0"?"BACK":"LAY")}} sx={{ padding: "6px" }}>
                                                                    <RotateLeftIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    : item?.status == 1 ?
                                                        <>
                                                            <Typography sx={{ bgcolor: '#125f09', color: "#FFF", textAlign: "center", borderRadius: "5px" }}> {"Eşleşmiş"}</Typography>
                                                            <Grid item sx={{ textAlign: "center" }} >
                                                                <IconButton aria-label="delete" size="large" sx={{ padding: "6px" }}>
                                                                    <RotateLeftIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Typography sx={{ bgcolor: '#970000', color: "#fff", textAlign: "center", borderRadius: "5px" }}> {"İptal"}</Typography>
                                                }
                                            </> : <CircularProgress color="primary" />}
                                    </Grid>
                                </Grid>

                            </Grid>
                        :                            <Grid key={item?.betfair_id+"a1"+item?.odd_id}>
                        <Grid container sx={{ borderBottom: "solid 1px", alignItems: "center", paddingTop: "10px",paddingLeft:"20px",color:"#ff0000", paddingBottom: "5px" }}>
                            {item.message}
                            </Grid>
                            </Grid>

                        }</>
                        ))}
                        <Grid sx={{ width: "100%", padding: "10px", backgroundColor: "#1e1e1e", cursor: "pointer" }} onClick={handleNavigate}>
                            <Typography sx={{ fontSize: "0.875rem", fontWeight: "500", textAlign: "center", cursor: "pointer" }} onClick={handleNavigate}>{"BAHİSLERİM"}</Typography>
                        </Grid>
                    </Paper>
                </>}
                <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Teklifi iptal edilecek, emin misiniz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bu işlemi gerçekleştirmek istediğinizden emin misiniz? İptal işlemi geri alınamaz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Hayır
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Coupon;
