import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button, Typography, FormControl, InputLabel, Select, MenuItem, Stack,CircularProgress,Snackbar,Alert  } from '@mui/material';
import { apiGetFeedRequest } from '../../config';
import LockIcon from '@mui/icons-material/Lock';
import BetPlacingComponent from './slipdesktop';
import Loading from '../loading';
import { useNavigate,Link  } from 'react-router-dom';
const InPlayMatches = ({ activeleague, setlivematchcount,setBalance,counts }) => {
  let navigate = useNavigate();
  const [matches, setMatches] = useState([]);
  const [responsedMathes, setResponsedMathes] = useState([]);
  const [selectedmarket, setSelectedmarket] = useState("Maç Sonucu");
  const [viewmode, setViewmode] = useState("Lig Görünümü");
  const [expandedMatchId, setExpandedMatchId] = useState(null);
  const [selectedodddetail, setselectedodddetail] = useState(null);
  const [count, setCount] = useState(counts);
  const [groupedMatches,setGroupedMatches]= useState([]);
  const [loading, setLoading] = useState(true)
  const [loadingcount, setLoadingcount] = useState(false);
  const [addvisible, setAddVisible] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [status,setStatus] = React.useState("error");
  const [statustext,setStatustext] = React.useState();
  useEffect(() => {

      const token = localStorage.getItem("authToken");
      let par = ""
      if (activeleague) {
          par = "&competitionIds=" + activeleague
      }
      const fetchData = async () => {
          try {
              const response = await apiGetFeedRequest("/api/events/listliveevent?count=" + count + par, token);
              if (response) {
                  const filteredMatches = response.filter(match => {
                      return match.marketCatalogues && match.marketCatalogues.some(market => market.marketName === 'Maç Sonucu');
                  });
                  setResponsedMathes(filteredMatches);
                  if (response.length > 0) {
                      setlivematchcount(true)
                  } else {
                      setlivematchcount(false)
                  }
                  setLoading(false)
                  setLoadingcount(false)
                  if (response.length == count) {
                      setAddVisible(true)
                  } else {
                      setAddVisible(false)
                  }
              }
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };

      const intervalId = setInterval(fetchData, 2000);

      fetchData();

      return () => clearInterval(intervalId);
  }, [activeleague, count]);
  function routedetail(id) {
      navigate('/event/' + id);  // Kullanıcıyı '/home' adresine yönlendirir
  }
  useEffect(() => {
      const filteredMatches = responsedMathes.filter(match =>
        match.marketCatalogues.some(market => market.marketName === (selectedmarket || "Maç Sonucu") && !match?.state || match?.state?.status== "IN_PLAY")
      );
      setMatches(filteredMatches);
  }, [selectedmarket, responsedMathes]);

  const addcount = () => {
      setLoadingcount(true)
      setCount(count + 25)
  }
  const handleExpandClick = (matchId, type, price, oddname, oddid, marketname, matchname, marketid) => {
      if (expandedMatchId === matchId && selectedodddetail.oddid === oddid && selectedodddetail.type === type) {
          setExpandedMatchId(null);
          setselectedodddetail(null);
      } else {
          setExpandedMatchId(matchId);
          setselectedodddetail({ matchId: matchId, type: type, price: price, oddname: oddname, marketname: marketname, matchname: matchname, oddid: oddid, marketid: marketid });
      }
  };
  function formatDayMonthYear(dateString) {
      const parts = dateString.split('-'); // Tarih formatını varsayılan olarak kabul ediyorum: YYYY-MM-DD
      const year = parts[0];
      const month = parts[1];
      const day = parts[2].substring(0, 2); // İlk iki karakteri alıyoruz (01, 02, ..., 31)
      return `${day}.${month}.${year}`;
  }


  function formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'UTC' // Saat dilimini belirtiyoruz
      };
      const formattedDate = date.toLocaleDateString('tr-TR', options);
      return formattedDate;
  }
  const handleViewmode = (val) => {
      setViewmode(val);
  }
  const handleChangemarket = (val) => {
      setSelectedmarket(val);

  }
  useEffect(() => {
    const groupedMatches = matches.reduce((acc, match) => {
        let date;
        if (viewmode === "Lig Görünümü") {
            date = match.competitionName;
        } else {
            date = formatDayMonthYear(match.openDate);
        }

        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(match);
        return acc;
    }, {});
    
    setGroupedMatches(groupedMatches);
}, [matches, viewmode]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
    return (
        <Grid container>
            {responsedMathes.length > 0 ?
                <>
                    <Grid container sx={{ alignItems: "center", marginTop: "10px", backgroundColor: "#333333", borderBottom: "solid 1px", alignContent: "center" }}>
                        <Grid item xs={3} >
                            <Typography sx={{ fontSize: "16px", textAlign: "start", fontWeight: "800", color: "#ff6c00", paddingLeft: "15px" }}>{"In-Play"}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container sx={{ justifyContent: "end", alignItems: "end" }}>
                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                    <FormControl sx={{ m: 0.5, minWidth: 120, padding: "5px 14px", fontSize: "0.7rem" }} size="small">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            sx={{ fontSize: "0.7rem" }}
                                            value={selectedmarket ? selectedmarket : "Maç Sonucu"}
                                            onChange={(e) => handleChangemarket(e.target.value)}
                                        >
                                            <MenuItem value="Maç Sonucu">
                                                1X2
                                            </MenuItem>
                                            <MenuItem value={"İlk Yarı"}>İlk Yarı</MenuItem>
                                            <MenuItem value={"Karşılıklı Gol"}>Karşılıklı Gol</MenuItem>
                                            <MenuItem value={"Üst/Alt 0.5"}>Üst/Alt 0.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 1.5"}>Üst/Alt 1.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 2.5"}>Üst/Alt 2.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 3.5"}>Üst/Alt 3.5</MenuItem>
                                            <MenuItem value={"Üst/Alt 4.5"}>Üst/Alt 4.5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7} lg={7}>
                                    <FormControl sx={{ m: 0.5, minWidth: 120, padding: "5px 14px", fontSize: "0.7rem" }} size="small">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={viewmode}
                                            sx={{ fontSize: "0.7rem" }}
                                            onChange={(e) => handleViewmode(e.target.value)}
                                        >
                                            <MenuItem value={"Lig Görünümü"}>Lig Görünümü</MenuItem>
                                            <MenuItem value={"Tarih Görünümü"}>Tarih Görünümü</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading == true ? <Grid container sx={{ justifyContent: "center" }}><Loading /></Grid>
                        : <>
                    {matches.length > 0 ?
                        <>
                            {Object.entries(groupedMatches).map(([date, mathes1], index) => (
                                <React.Fragment key={index+"a"}>
                                    <Grid container sx={{ alignItems: "center", marginTop: "10px", backgroundColor: "#333333", borderBottom: "solid 1px", alignContent: "center" }}>
                                        <Grid item xs={12} >
                                            <Typography sx={{ fontSize: "18px", textAlign: "start", fontWeight: "800", color: "#fff", paddingLeft: "15px" }}>{viewmode == "Tarih Görünümü" ? "Live" : date}</Typography>
                                        </Grid>
                                    </Grid>
                                    {mathes1.map((match, matchIndex) => (
                                      match.status=="OPEN" &&
                                        <>
                                        
                                            <Grid item xs={12} key={matchIndex+"b"} sx={{ borderBottom: "solid 2px #707070" }}>
                                                <Paper>
                                                    <Grid container sx={{ justifyContent: "center", paddingBottom: "5px", paddingTop: "5px" }} onClick={()=>routedetail(match.id)}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="h6" sx={{ paddingLeft: "15px", fontSize: "1rem" }} >{match.name}</Typography>
                                                        </Grid>
                                                        {/* <Grid item xs={1} sm={1} md={1} lg={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" >{"Live"}</Typography>
                                    </Grid> */}
                                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#3838', color: "#ebebeb" }}>
                                                            <Grid container>
                                                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                                                    <Typography variant="h6" sx={{ paddingLeft: "15px", fontSize: "1rem" }} >{match?.state?.timeElapsed && match?.state?.timeElapsed}'</Typography>
                                                                </Grid>
                                                                <Grid item xs={5} sm={5} md={5} lg={5}>
                                                                    <Typography variant="h6" sx={{ paddingLeft: "15px", fontSize: "1rem" }} >{match?.state?.score?.home?.score && match?.state?.score?.home?.score + " - " + match?.state?.score?.away?.score}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                    <Typography variant="h6" sx={{ paddingLeft: "15px", fontSize: "1rem" }} >{"LIVE"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ justifyContent: "center", justifyItems: "center" }}>
                                                        {/* Market kataloglarını render et */}
                                                        {match.marketCatalogues
                                                            .filter(market => market.marketName == (selectedmarket ? selectedmarket : "Maç Sonucu"))
                                                            .map((market, marketIndex) => {
                                                                if (market.runners.length > 0) {
                                                                    if (market.numberOfRunners === 3) {
                                                                        const runners = market.runners;
                                                                        const runner0 = runners[0];
                                                                        const runner2 = runners[2];
                                                                        const runner1 = runners[1];
                                                                        if (runners.length >= 3) {
                                                                            return (
                                                                                <React.Fragment key={marketIndex+"c"}>
                                                                                    <Grid container sx={{ borderBottom: "solid 1px" }}>
                                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                            <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{runner0.runnerName}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                            <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{runner2.runnerName}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                                            <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{runner1.runnerName}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid container justifyContent="center" p={1}>
                                                                                        {market.status == "OPEN" ?
                                                                                           <>
                                                                                           <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{  backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner0.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#a6d8ff"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner0.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#a6d8ff") }, color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "BACK", runner0.ex.availableToBack[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner0.ex.availableToBack[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner0.ex.availableToBack[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner0.ex.availableToBack[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner0.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#fac9d1"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner0.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#fac9d1") } , color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "LAY", runner0.ex.availableToLay[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner0.ex.availableToLay[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner0.ex.availableToLay[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner0.ex.availableToLay[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                           </Grid>
                                                                                           <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{ backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner2.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#a6d8ff"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner2.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#a6d8ff") }, color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "BACK", runner2.ex.availableToBack[0]?.price, runner2.runnerName, runner2.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner2.ex.availableToBack[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner2.ex.availableToBack[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner2.ex.availableToBack[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{ backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner2.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#fac9d1"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner2.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#fac9d1") } , color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "LAY", runner2.ex.availableToLay[0]?.price, runner2.runnerName, runner2.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner2.ex.availableToLay[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner2.ex.availableToLay[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner2.ex.availableToLay[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                           </Grid>
                                                                                           <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{ backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner1.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#a6d8ff"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "BACK" &&
                                                                                                selectedodddetail?.oddid === runner1.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#a6d8ff") }, color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px", marginRight: "3px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "BACK", runner1.ex.availableToBack[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner1.ex.availableToBack[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner1.ex.availableToBack[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner1.ex.availableToBack[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                             <Button 
                                                                                               variant="contained" 
                                                                                               sx={{ backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id  &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner1.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#fac9d1"),'&:hover, &:focus': {
                                                                                                backgroundColor: 
                                                                                                selectedodddetail?.matchId === match.id &&
                                                                                                selectedodddetail?.type === "LAY" &&
                                                                                                selectedodddetail?.oddid === runner1.selectionId &&
                                                                                                selectedodddetail?.marketid === market.marketId
                                                                                                ? ("#ff6c00") : ("#fac9d1") }, color: "#000", padding: "6px 10px", minWidth: "54px", maxWidth: "54px" }} 
                                                                                               small
                                                                                               onClick={() => handleExpandClick(match.id, "LAY", runner1.ex.availableToLay[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                             >
                                                                                               {runner1.ex.availableToLay[0]?.price ? (
                                                                                                 <Stack direction="column" spacing={0}>
                                                                                                   <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                     {runner1.ex.availableToLay[0]?.price}
                                                                                                   </Typography>
                                                                                                   <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                     {runner1.ex.availableToLay[0]?.size}
                                                                                                   </Typography>
                                                                                                 </Stack>
                                                                                               ) : (
                                                                                                 <LockIcon sx={{ color: "#616161" }} />
                                                                                               )}
                                                                                             </Button>
                                                                                           </Grid>
                                                                                         </>
                                                                                         
                                                                                            :
                                                                                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', border: "solid 1px" }}>
                                                                                                <Typography sx={{ textAlign: "center", fontWeight: "800", paddingTop: "5px", paddingBottom: "5px" }}>{market?.why?market?.why:"SUSPEND"}</Typography>
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ alignItems: "end", justifyItems: "end", paddingRight: "15px", borderTop: "solid 1px" }}>
                                                                                        <Typography sx={{ fontSize: "14px", textAlign: "end", fontWeight: "800", color: "#757575" }}>{"Eşleşmiş : " + market.totalMatched}</Typography>
                                                                                    </Grid>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    } else {
                                                                        const runners = market.runners;
                                                                        const runner0 = runners[0];
                                                                        const runner1 = runners[1];
                                                                        return (
                                                                            <React.Fragment key={marketIndex+"d"}>
                                                                                <Grid container sx={{ borderBottom: "solid 1px" }}>
                                                                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                        <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{runner0.runnerName}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                        <Typography sx={{ fontSize: "14px", textAlign: "center", fontWeight: "800", color: "#7f7f7f" }}>{runner1.runnerName}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container justifyContent="center" p={1}>
                                                                                    {market.status == "OPEN" ?
                                                                                      <>
                                                                                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                                                                                        <Button 
                                                                                          variant="contained" 
                                                                                          sx={{ backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id  &&
                                                                                            selectedodddetail?.type === "BACK" &&
                                                                                            selectedodddetail?.oddid === runner0.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                        ? ("#ff6c00") : ("#a6d8ff"),'&:hover, &:focus': {
                                                                                            backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id &&
                                                                                            selectedodddetail?.type === "BACK" &&
                                                                                            selectedodddetail?.oddid === runner0.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#a6d8ff") }, color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px", marginRight: "3px" }} 
                                                                                          small
                                                                                          onClick={() => handleExpandClick(match.id, "BACK", runner0.ex.availableToBack[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                        >
                                                                                          {runner0.ex.availableToBack[0]?.price ? (
                                                                                            <Stack direction="column" spacing={0}>
                                                                                              <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                {runner0.ex.availableToBack[0]?.price}
                                                                                              </Typography>
                                                                                              <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                {runner0.ex.availableToBack[0]?.size}
                                                                                              </Typography>
                                                                                            </Stack>
                                                                                          ) : (
                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                          )}
                                                                                        </Button>
                                                                                        <Button 
                                                                                          variant="contained" 
                                                                                          sx={{ backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id  &&
                                                                                            selectedodddetail?.type === "LAY" &&
                                                                                            selectedodddetail?.oddid === runner0.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                        ? ("#ff6c00") : ("#fac9d1"),'&:hover, &:focus': {
                                                                                            backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id &&
                                                                                            selectedodddetail?.type === "LAY" &&
                                                                                            selectedodddetail?.oddid === runner0.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#fac9d1") }, color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px" }} 
                                                                                          small
                                                                                          onClick={() => handleExpandClick(match.id, "LAY", runner0.ex.availableToLay[0]?.price, runner0.runnerName, runner0.selectionId, market.marketName, match.name, market.marketId)}
                                                                                        >
                                                                                          {runner0.ex.availableToLay[0]?.price ? (
                                                                                            <Stack direction="column" spacing={0}>
                                                                                              <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                {runner0.ex.availableToLay[0]?.price}
                                                                                              </Typography>
                                                                                              <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                {runner0.ex.availableToLay[0]?.size}
                                                                                              </Typography>
                                                                                            </Stack>
                                                                                          ) : (
                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                          )}
                                                                                        </Button>
                                                                                      </Grid>
                                                                                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                                                                                        <Button 
                                                                                          variant="contained" 
                                                                                          sx={{ backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id  &&
                                                                                            selectedodddetail?.type === "BACK" &&
                                                                                            selectedodddetail?.oddid === runner1.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                        ? ("#ff6c00") : ("#a6d8ff"),'&:hover, &:focus': {
                                                                                            backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id &&
                                                                                            selectedodddetail?.type === "BACK" &&
                                                                                            selectedodddetail?.oddid === runner1.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#a6d8ff") }, color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px", marginRight: "3px" }} 
                                                                                          small
                                                                                          onClick={() => handleExpandClick(match.id, "BACK", runner1.ex.availableToBack[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                        >
                                                                                          {runner1.ex.availableToBack[0]?.price ? (
                                                                                            <Stack direction="column" spacing={0}>
                                                                                              <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                {runner1.ex.availableToBack[0]?.price}
                                                                                              </Typography>
                                                                                              <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                {runner1.ex.availableToBack[0]?.size}
                                                                                              </Typography>
                                                                                            </Stack>
                                                                                          ) : (
                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                          )}
                                                                                        </Button>
                                                                                        <Button 
                                                                                          variant="contained" 
                                                                                          sx={{ backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id  &&
                                                                                            selectedodddetail?.type === "LAY" &&
                                                                                            selectedodddetail?.oddid === runner1.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                        ? ("#ff6c00") : ("#fac9d1"),'&:hover, &:focus': {
                                                                                            backgroundColor: 
                                                                                            selectedodddetail?.matchId === match.id &&
                                                                                            selectedodddetail?.type === "LAY" &&
                                                                                            selectedodddetail?.oddid === runner1.selectionId &&
                                                                                            selectedodddetail?.marketid === market.marketId
                                                                                            ? ("#ff6c00") : ("#fac9d1") }, color: "#000", padding: "6px 10px", minWidth: "74px", maxWidth: "74px" }} 
                                                                                          small
                                                                                          onClick={() => handleExpandClick(match.id, "LAY", runner1.ex.availableToLay[0]?.price, runner1.runnerName, runner1.selectionId, market.marketName, match.name, market.marketId)}
                                                                                        >
                                                                                          {runner1.ex.availableToLay[0]?.price ? (
                                                                                            <Stack direction="column" spacing={0}>
                                                                                              <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                                                {runner1.ex.availableToLay[0]?.price}
                                                                                              </Typography>
                                                                                              <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                                                {runner1.ex.availableToLay[0]?.size}
                                                                                              </Typography>
                                                                                            </Stack>
                                                                                          ) : (
                                                                                            <LockIcon sx={{ color: "#616161" }} />
                                                                                          )}
                                                                                        </Button>
                                                                                      </Grid>
                                                                                    </>
                                                                                    
                                                                                        :
                                                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center', border: "solid 1px" }}>
                                                                                            <Typography sx={{ textAlign: "center", fontWeight: "800", paddingTop: "5px", paddingBottom: "5px" }}>{market?.why?market?.why:"SUSPEND"}</Typography>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ alignItems: "end", justifyItems: "end", paddingRight: "15px", borderTop: "solid 1px" }}>
                                                                                    <Typography sx={{ fontSize: "14px", textAlign: "end", fontWeight: "800", color: "#757575" }}>{"Eşleşmiş : " + market.totalMatched}</Typography>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                }
                                                            })}
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            {expandedMatchId === match.id && (
                                                <Grid item xs={12}>
                                                    <BetPlacingComponent setOpen={setOpen} setBalance={setBalance} setStatus={setStatus} setStatustext={setStatustext} setExpandedMatchId={setExpandedMatchId} selectedodddetail={selectedodddetail} setselectedodddetail={setselectedodddetail} type={selectedodddetail.type} price={selectedodddetail.price} oddname={selectedodddetail.oddname} marketname={selectedodddetail.marketname} matchname={selectedodddetail.matchname} matchid={match.id} oddid={selectedodddetail.oddid} marketid={selectedodddetail.marketid} />
                                                </Grid>
                                            )}</>
                                    ))}
                                </React.Fragment>
                            ))}
                                                                                                    {addvisible && 
                                    <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "10px", backgroundColor: "#1e1e1e" }}>
                                        <Button
                                            variant="contained"
                                            onClick={addcount}
                                            disabled={loadingcount}
                                            sx={{ position: 'relative', width: '160px', height: '40px' }} // Sabit genişlik ve yükseklik tanımla
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                {loadingcount ? (
                                                    <CircularProgress size={24} sx={{ color: 'white' }} />
                                                ) : (
                                                    "Daha Fazla Maç"
                                                )}
                                            </div>
                                        </Button>
                                    </Grid>}
                        </> : <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "20px", backgroundColor: "#1e1e1e" }}>
                            <Typography variant={"h6"}>Uygun Maç Bulunamadı</Typography>
                        </Grid>}</>}
                </> : ""}
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
            onClose={handleClose}
            color={status}
            variant="filled"
            sx={{ width: '100%',color:"#fff"}}
            >
            {statustext}  <Link to="/my-bets" style={{  color: '#000' }}>
                BAHİSLERİM
            </Link>
            </Alert>
            </Snackbar>
        </Grid>
    );
};

export default InPlayMatches;
