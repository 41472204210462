import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, Typography, Collapse, Grid, ListItemIcon, Button, useMediaQuery } from '@mui/material';
import { SportsSoccer, Star } from '@mui/icons-material'; // Futbol topu ve yıldız simgeleri
import { apiGetFeedRequest } from '../config';
import useAuth from '../api/auth';
const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    marginRight: 0, // Sağ boşluğu kaldır
  },
}));

const QuickLinks = React.memo(({ activeleague, setLeague }) => {
  const [ligler, setLigler] = useState([]);
  const [loading, setLoading] = useState(true); // İlk veri getirme durumu
  const isMobile = useMediaQuery('(max-width:1280px)');
  const auth=useAuth();
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const cachedCompetitions = JSON.parse(localStorage.getItem('competitions'));

    if (cachedCompetitions) {
      setLigler(cachedCompetitions);
      setLoading(false);
    } else {
      apiGetFeedRequest("/api/competitions/listcompetitions", token)
        .then(response => {
          setLigler(response);
          localStorage.setItem('competitions', JSON.stringify(response)); // Yarışmaları önbelleğe al
        })
        .catch(error => {
          console.error('Veri getirme hatası:', error);
          setLoading(false);
        });
    }
  }, []);

  const classes = useStyles();
  const [open, setOpen] = useState({});
  const ulkeler = [...new Set(ligler.map((lig) => lig.competitionRegion))];

  const getUlkeNameById = (id) => {
    const ulke = ulkeler?.find((ulke) => ulke.id === id);
    return ulke ? ulke.name : "";
  };

  const handleClick = (ulkeId) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [ulkeId]: !prevOpen[ulkeId]
    }));
  };

  const [activeButton, setActiveButton] = useState('favleagues');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <>
      <Grid sx={{ width: "95%", paddingLeft: "15px", paddingTop: "15px"}}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <List sx={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
              <Grid sx={{ borderBottom: "solid 1px #fff" }}>
                <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }}>Hızlı Linkler</Typography>
              </Grid>
              {ligler.map((lig, index) => {
                return (
                  index < 11 && (
                    <React.Fragment key={lig.id}>
                      <ListItem button onClick={() => setLeague(lig.id)} sx={{ padding: "3px 0px", borderBottom: "solid 1px", backgroundColor: lig.id == activeleague ? "#ff6c00" : "#3d3d3d", color: lig.id == activeleague ? "#fff" : "#ff6c00" }}>
                        <ListItemIcon className={classes.listItemIcon} sx={{minWidth:"16px"}}>
                          <SportsSoccer /> {/* Futbol topu simgesi */}
                        </ListItemIcon>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{lig.name}</Typography>
                      </ListItem>
                    </React.Fragment>
                  )
                );
              })}
              { auth.isLoggedIn() &&
              <>
              {[{text: "Bahis Geçmişim"}, {text: "Hesabım"}, {text: "Para Yatır"}, {text: "Para Çek"}].map((item, index) => (
                <ListItem key={index} button sx={{ padding: "3px 0px", borderBottom: "solid 1px", backgroundColor:  "#3d3d3d", color: "#ff6c00" }}>
                  <ListItemIcon className={classes.listItemIcon} sx={{minWidth:"16px"}}>
                    <Star /> {/* Yıldız simgesi */}
                  </ListItemIcon>
                  <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{item.text}</Typography>
                </ListItem>
              ))}
              </>
              }
            </List>
          </>
        )}
      </Grid>
    </>
  );
});

export default QuickLinks;
