import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Grid, Typography, Menu, MenuItem, Snackbar, Alert } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Logo from '../media/logosvg';
import DesktopMenu from './desktopmenu';
import MobileMenu from './mobilemenu';
import { apiRequest } from '../config';
import useAuth from '../api/auth';

const Header = ({ refreshComponent, balance, setBalance }) => {
  const auth = useAuth();
  const isMobile = useMediaQuery('(max-width:1280px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [status, setStatus] = useState("error");
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const formatCurrency = (value) => {
    return value?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleLoginDialog = () => {
    setLoginDialogOpen(!loginDialogOpen);
  };

  const toggleForgotPasswordDialog = () => {
    setForgotPasswordOpen(!forgotPasswordOpen);
  };

  const handleLogin = () => {
    if (username && password) {
      const payload = { username: username, pass: password }
      apiRequest("/login", payload)
        .then(response => {
          if (response?.username) {
            setBalance(response.balance)
            auth.login(response);
            localStorage.setItem("authToken", response.token)
            toggleLoginDialog();
            refreshComponent();
            setStatus("success")
            setOpen(true);
          } else {
            setStatus("error")
            setOpen(true);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleForgotPassword = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleResetPassword = () => {
    setForgotPasswordOpen(false);
  };

  const logout = () => {
    handleMenuClose();
    auth.logout();
    refreshComponent();
  }

  const handleKeyPress = (e) => {
    if(!(auth.isLoggedIn()) && !(auth?.getUser()?.username)){
      if (e.key === 'Enter') {
        handleLogin();
      }
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: 'space-between', minHeight: "96px" }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isMobile && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                <Logo />
              </Link>
            </div>
            {!isMobile && <DesktopMenu />}
          </div>
          {auth.isLoggedIn() && auth?.getUser()?.username ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isMobile ?
                <Grid container sx={{ justifyContent: "end" }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ alignItems: "center"}}>
                    <Button onClick={toggleLoginDialog} component={Link} to="/deposit" sx={{ backgroundColor: "#125f09",padding:"7.5px 6px",fontSize:"0.7rem" }} variant="contained" startIcon={<AccountBalanceWalletIcon />}>
                      {formatCurrency(balance)}TRY
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Grid container >
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<AccountCircleIcon />}
                          size={isMobile ? "small" : "medium"}
                          onClick={handleMenuOpen}
                          sx={{ padding: isMobile ? "6px 9px" : "6px 16px", fontSize: isMobile ? "0.775rem" : "0.875rem" }}
                        >
                          {auth?.getUser()?.username}
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem component={Link} to="/my-bets" onClick={handleMenuClose}>Bahislerim</MenuItem>
                          <MenuItem component={Link} to="/my-transactions" onClick={handleMenuClose}>İşlemlerim</MenuItem>
                          <MenuItem component={Link} to="/my-profile" onClick={handleMenuClose}>Hesabım</MenuItem>
                          <MenuItem component={Link} to="/deposit">Para Yatır</MenuItem>
                          <MenuItem component={Link} to="/withdraw" onClick={handleMenuClose} >Para Çek</MenuItem>
                          <MenuItem onClick={logout}>Çıkış Yap</MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid container sx={{ justifyContent: "end", marginRight: "10px" }}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                    component={Link} to="/deposit"
                      variant="contained"
                      size="small"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: "#125f09"
                      }}
                    >
                      <Box>
                        <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: 11 }}>{formatCurrency(balance)}</Typography>
                      </Box>
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleMenuOpen}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Box>
                        <AccountCircleIcon sx={{ fontSize: 24 }} />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: 11 }}>{auth.getUser().username}</Typography>
                      </Box>
                    </Button>
                  </Grid>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem component={Link} to="/my-bets" onClick={handleMenuClose} sx={{ borderBottom: "solid 1px #414141" }}>Bahislerim</MenuItem>
                    <MenuItem component={Link} to="/my-transactions" onClick={handleMenuClose} sx={{ borderBottom: "solid 1px #414141" }}>İşlemlerim</MenuItem>
                    <MenuItem component={Link} to="/my-profile" onClick={handleMenuClose} sx={{ borderBottom: "solid 1px #414141" }}>Hesabım</MenuItem>
                    <MenuItem component={Link} to="/deposit" onClick={handleMenuClose} sx={{ borderBottom: "solid 1px #414141" }}>Para Yatır</MenuItem>
                    <MenuItem component={Link} to="/withdraw" onClick={handleMenuClose} sx={{ borderBottom: "solid 1px #414141" }}>Para Çek</MenuItem>
                    <MenuItem onClick={logout} sx={{ borderBottom: "solid 1px #414141" }}>Çıkış Yap</MenuItem>
                  </Menu>
                </Grid>
              }
            </div>
            :
            isMobile ? (
              <Button onClick={toggleLoginDialog} color="primary" variant="contained" startIcon={<AccountCircleIcon />}>
                Giriş Yap
              </Button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container sx={{ alignItems: "center", justifyContent: "end" }}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <TextField
                      label="Kullanıcı Adı"
                      variant="outlined"
                      value={username}
                      color="primary"
                      size="small"
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={handleKeyPress}
                      style={{ marginRight: '10px' }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <TextField
                      label="Şifre"
                      variant="outlined"
                      type="password"
                      size="small"
                      InputProps={{
                        style: { color: '#ff6c00' }
                      }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                      style={{ marginRight: '10px' }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Button variant="contained" color="primary" onClick={handleLogin}>
                      Giriş Yap
                    </Button>
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={10} >
                    <Grid container >
                      <Grid item xs={6} sm={6} md={6} lg={6} sx={{ marginTop: "5px" }}>
                        <a
                          onClick={handleForgotPassword}
                          color="primary">
                          Şifremi Unuttum
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{
            width: "70%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "70%",
              backgroundColor: "#000000"
            },
          }}
        >
          <MobileMenu toggleDrawer={toggleDrawer} toggleLoginDialog={toggleLoginDialog} />
        </Drawer>
      )}
      <Dialog open={forgotPasswordOpen} onClose={handleForgotPasswordClose} PaperProps={{ sx: { width: '400px' } }}>
        <DialogTitle>Şifremi Unuttum</DialogTitle>
        <DialogContent>
          <TextField
            label="E-posta Adresi"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px', marginTop: "2px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleResetPassword} color="primary" variant="contained">
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
      {isMobile && (
        <Box>
          <Dialog open={loginDialogOpen} onClose={toggleLoginDialog}>
            <DialogTitle>Giriş Yap</DialogTitle>
            <DialogContent>
              <TextField
                label="Kullanıcı Adı"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Şifre"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <Button onClick={handleForgotPassword} color="primary">
                Şifremi Unuttum
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleLoginDialog} color="primary">
                İptal
              </Button>
              <Button onClick={handleLogin} color="primary" variant="contained">
                Giriş Yap
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={status}
          variant="filled"
          sx={{ width: '100%', color: "#fff" }}
        >
          {status === "success" ?
            "Giriş Başarılı!" : "Şifre veya Kullanıcı Adı Yanlış!"
          }
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
