import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LigMenu from './leaguemenu';
import { Button, Drawer, List, ListItem, ListItemText,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { apiGetFeedRequestSearch } from '../config';
import { useNavigate } from 'react-router-dom';
export default function DesktopSearch({ isDrawerOpen, activeleague, toggleDrawer, setLeague,isEvent }) {
  let navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  function routedetail(id) {
    setSearchQuery('');
    navigate('/event/'+id); 
  }
  useEffect(() => {
    // Fetch search results when the search query changes
    const fetchSearchResults = async () => {
      if (searchQuery.length > 3) {
        try {
          const response = await apiGetFeedRequestSearch(`/api/events/search?str=${searchQuery}`);
          setSearchResults(response);
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginTop: !isEvent&&"15px",
          border:"solid 1px"
        }}
      >

        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Müsabaka Ara"
          inputProps={{ 'aria-label': 'Müsabaka Ara',color:"#ff6c00"}}
          value={searchQuery}
          onChange={handleInputChange}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      </Paper>
      {searchResults.length>0&&
        <List sx={{ zIndex: "1250", position: "fixed", backgroundColor: "#272727", width: isEvent?"16.5%":"19.5%", left: isEvent?"54%":"61.5%" }}>
  {searchResults.map((match) => (
    <ListItem key={match.id}>
      <ListItemText 
        sx={{cursor: 'pointer'}} onClick={()=>routedetail(match.id)}
        primary={match.name} 
        secondary={match.competitionName} 
      />
      <ListItemText 
        primary={!match?.state ? (
          <span style={{ fontWeight: 'bold', color: '#7f7f7f' }}>{new Date(match.openDate).toLocaleString()}</span>
        ) : ""} 
        secondary={match.state ? match?.state?.length>0 ?  (
          <span style={{ fontWeight: 'bold', color: 'green',fontSize:"1rem" }}>Live {match.state?.elapsedRegularTime}' / {match?.state?.score?.home["score"]+" - "+match?.state?.score?.away["score"]}</span>
        ) :   <span style={{ fontWeight: 'bold', color: 'green',fontSize:"1rem" }}>Live </span>:""} 
      />
    </ListItem>
  ))}
</List>
      }
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            top: '91px', // Adjust this value based on your header height
            height: 'calc(100% - 91px)', // Adjust this value based on your header height
            width: '240px', // Adjust the width as needed
            position: 'relative', // Ensure relative positioning for absolute positioning of close button
          },
          "& .MuiBackdrop-root": {
            top: '91px', // Adjust this value based on your header height
            height: 'calc(100% - 91px)', // Adjust this value based on your header height
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
          },
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
          onClick={toggleDrawer(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <LigMenu isDrawerOpen={isDrawerOpen} activeleague={activeleague} toggleDrawer={toggleDrawer} setLeague={setLeague}/>
      </Drawer>
    </>
  );
}
