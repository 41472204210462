import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { List, ListItem, Typography, Collapse, Grid, ListItemIcon, Button, useMediaQuery,Checkbox,FormControlLabel} from '@mui/material';
import getCountryISO3 from 'country-iso-2-to-3';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 'unset', // Remove the minimum width
    marginRight: theme.spacing(1), // Adjust margin as needed
  },
}));

const MarketMenu = React.memo(({activemarket,setActiveMarket,marketInfo,countryid,competition,competitonid,marketTogle,isEvent}) => {
  const isMobile = useMediaQuery('(max-width:1280px)');
  const [countryname,setCountryName]=useState();
  const classes = useStyles();
  const [open, setOpen] = useState({});
  const handleChange = (marketId) => {
      setActiveMarket({ market: marketId, status: true });
    }

    if(activemarket?.length==0){
      if(marketInfo[0]){
        setActiveMarket({ market: marketInfo[0].marketId, status: true })
      }
    }
    let navigate = useNavigate();

    const openLegue = (id,name) => {
      navigate('/', { state: { leagueid: id,name:name } });
    };
    const openCountry = (id) => {
      navigate('/', { state: { countryid: id } });
    };
    useEffect(() => {
      const cachedCountries = JSON.parse(localStorage.getItem('countries'));
      const mm = getCountryISO3(countryid)
      if(cachedCountries){
        const countrycode=cachedCountries.find(item=>item.id==mm);
        setCountryName(countrycode?.name)
      }
    }, [countryid])
    
    const handleClick = (ulkeId) => {
      setOpen(prevOpen => ({
        ...prevOpen,
        [ulkeId]: !prevOpen[ulkeId]
      }));
    };
  return (
    <>

      <Grid sx={{ width:isMobile?"98%": "90%", paddingLeft: "10px", paddingTop: isMobile?"30px":"25px", paddingBottom: isMobile ? "20px" : "0px" }}>
        {marketInfo.lenght<=0 ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
             <List sx={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
                <Grid sx={{ borderBottom: "solid 1px #fff" }}>
                  <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }}>Ülke & Lig</Typography>
                </Grid>
                      <ListItem button onClick={() => handleClick(countryid)} sx={{ padding: "0px", borderBottom: "solid 1px" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{countryname}</Typography>
                      </ListItem>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                              <ListItem button className={classes.nested} onClick={() => openLegue(competitonid,competition)}  sx={{ padding: "0px", backgroundColor: "#3d3d3d", color: "#838282", borderBottom: "solid 1px #626262" }}>
                                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>{competition}</Typography>
                              </ListItem>
                        </List>
                      </Collapse>
              </List>
<List sx={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
  <Grid sx={{ borderBottom: "solid 1px #fff" }}>
    <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }}>Marketler</Typography>
  </Grid>

  {marketInfo?.map((item, index) => (
    <ListItem 
      button 
      key={item.marketId}
      sx={{ padding: "0px", fontSize: "0.8rem", borderBottom: "solid 1px" }}
      onClick={() => {handleChange(item.marketId); isEvent? marketTogle(false):""}}
    >
      <Checkbox
        checked={activemarket.market === item.marketId ? true : false}
        sx={{ padding: "2px 10px" }}
        onChange={() => {handleChange(item.marketId); isEvent? marketTogle(false):""}}
      />
      <Typography sx={{ fontSize: "0.8rem", flex: '1' }}>{item.marketName}</Typography>
    </ListItem>
  ))}
</List>

          </>
        )}
      </Grid>
    </>
  );
});

export default MarketMenu;
