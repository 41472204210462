import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Typography, ToggleButtonGroup, ToggleButton, IconButton,Snackbar,Alert,Button,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {apiRequest} from '../config';
import Loading from '../components/loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Logo from '../media/logosvg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/tr';
dayjs.extend(utc);
dayjs.extend(timezone);

import { useNavigate } from 'react-router-dom';

const MyTransactions = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [opensnack, setOpenSnack] = useState(false);
    const [status,setStatus] = useState("error");
    const [statustext,setStatustext] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
    const [datas, setFiltredDatas] = useState([]);
    const [statuscoup, setStatuscoup] = useState("all");
    const [profit,setProfit] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            setIsMediumScreen(window.innerWidth > 1280 && window.innerWidth < 1800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
      
        document.title = "Thexch | Bahislerim";


      }, []);
      useEffect(() => {
        dayjs.locale('tr');
    }, []);
    useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem("authToken");
        const payload = {}; // payload'ı nesne olarak başlat
        if (dateRange[0] && dateRange[1]) { // & yerine && kullanılmalı
            const date1 = dayjs(dateRange[0], 'DD-MM-YYYY').format('YYYY-MM-DD');
            const date2 = dayjs(dateRange[1], 'DD-MM-YYYY').format('YYYY-MM-DD');
            Object.assign(payload, { date1: date1, date2: date2 }); // payload'ı nesne olarak güncelle
        }

        apiRequest("/api/transaction/transaction-report", payload, token)
            .then(response => {
                if (response) {
                    setData(response);
                    setLoading(false);
              
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [dateRange]); // useEffect'in bağımlılıkları arasında statuscoup var, doğru şekilde yazılmış
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnack(false);
      };

    const handleChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    const filteredData = datas?.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const sortedData = filteredData.sort((a, b) => {
        if (order === 'desc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
        }
    });

useEffect(() => {
    if(statuscoup==2){
        setFiltredDatas(data.filter(item=>item.type_id==8 || item.type_id==1))
    }else if(statuscoup==3){
        setFiltredDatas(data.filter(item=>item.type_id==9 || item.type_id==10))
    }else if(statuscoup==4){
        setFiltredDatas(data.filter(item=>item.type_id==14 ||item.type_id==19))
    }else if(statuscoup==5){
        setFiltredDatas(data.filter(item=>item.type_id==2||item.type_id==3||item.type_id==4  || item.type_id === 17 || item.type_id === 18))
        const total2And4 = data
        .filter(obj => obj.type_id === 2 || obj.type_id === 4 || obj.type_id === 19)
        .reduce((sum, obj) => sum + Number(obj.amount), 0);
    
        const total3And14 = data
            .filter(obj => obj.type_id === 3 || obj.type_id === 14 || obj.type_id === 17 || obj.type_id === 18) 
            .reduce((sum, obj) => sum + Number(obj.amount), 0);
    
  
      const difference = total2And4 - total3And14;
      setProfit(difference.toFixed(2))
    }else{
        setFiltredDatas(data)
    }
    
}, [statuscoup,data])


    const handleChanges = (event, newAlignment) => {
        setStatuscoup(newAlignment);
    };
    const handleOpenDialog = (coupon_id) => {
        setCouponToDelete(coupon_id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCouponToDelete(null);
    };

    const handleConfirmDelete = () => {
        deleteCoup(couponToDelete);
        handleCloseDialog();
    };

    const deleteCoup = (id) => {
        const token = localStorage.getItem("authToken");
        // Setting loading state on the specific coupon to true
        setData(data.map(item => item.coupon_id === id ? { ...item, loading: true } : item));

        let params = { coupon_id: id };
        apiRequest("/api/coupon/cancelcoupon", params, token)
            .then(response => {
                if (response.success === true) {
                    // Update the coupon with new status and loading state
                    const updatedConfirmedOdds = data.map(item => {
                        if (item.coupon_id === id) {
                            return { ...item, loading: false, status: 3 }; // Assuming '3' is the status for cancelled
                        }
                        return item;
                    });
                    setData(updatedConfirmedOdds);
                    setOpenSnack(true);
                    setStatus("success");
                    setStatustext( "Teklifiniz Başarıyla İptal Edildi.");

                }else{
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
                }
            }).catch(error => {
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
              
            });
    }

    const handleDateChange = (newDateRange) => {
        // console.log(newDateRange)
        // dayjs.locale('tr')
        // newDateRange[0]?.$L = "tr"
        // newDateRange[1]?.$L = "tr"
        setDateRange(newDateRange);
    };
    const [expandedRows, setExpandedRows] = useState({});
    useEffect(() => {
        // Belirli z-index değerine sahip <div> öğelerini kaldır
        const removeSpecificDiv = () => {
          const divs = document.querySelectorAll('div');
          divs.forEach(div => {
            const style = div.getAttribute('style');
            if (style && style.includes('z-index: 100000;')) {
              div.remove();
            }
          });
        };
    
        // removeSpecificDiv fonksiyonunu 500ms'de bir çalıştır
        const intervalId = setInterval(removeSpecificDiv, 500);
    
        // Bileşen kaldırıldığında interval'i temizle
        return () => {
          clearInterval(intervalId);
        };
      }, []);
    const toggleExpand = (coupon_id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [coupon_id]: !prevExpandedRows[coupon_id]
        }));
    };
    
    const getStatus = (id) => {
        switch (id) {
            case "0":
                return "Teklifte"
            case "1":
                return "Eşleşmiş"
                break;
            case "2":
                return "Sonuçlanmış"
                break;
            case "3":
                return "İptal"
                break;
            case "4":
                return "Lapsed"
                break;
            case "5":
                return "Voided"
                break;
            default:
                break;
        }

    }
    const handleClick = (id) => {
        navigate(`/event/${id}`);
    };
    dayjs.locale('tr')
    return (
        <>
        <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%" }}>
            <Grid item xs={11} sm={11} md={8} lg={8}>
            <Typography variant='h3' sx={{fontWeight:"200",fontSize:isSmallScreen?"2rem":"3rem"}} >İŞLEMLERİM</Typography>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px',borderBottom:"solid" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6} lg={8} sx={{ marginRight: isMediumScreen && "45px" }}>
                    
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={statuscoup}
                                    exclusive
                                    fullWidth={isMobile}
                                    onChange={handleChanges}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value={"all"} size={isMobile ? "small" : "medium"}>Tümü</ToggleButton>
                                    <ToggleButton value={2} size={isMobile ? "small" : "medium"}>Para Yatırma</ToggleButton>
                                    <ToggleButton value={3} size={isMobile ? "small" : "medium"}>Para Çekme</ToggleButton>
                                    <ToggleButton value={4} size={isMobile ? "small" : "medium"}>Fee İşlemleri</ToggleButton>
                                    <ToggleButton value={5} size={isMobile ? "small" : "medium"}>Bahis İşlemleri</ToggleButton>
                                </ToggleButtonGroup>
                                 {statuscoup==5&&<Typography sx={{color:profit>=0?"#52c100":"#c10000",fontSize:"1.2rem",border:"solid 1px",borderColor:profit>=0?"#52c100":"#c10000",marginTop:"10px",padding:"5px"}}>{"Exchange Profit:"+profit}</Typography>}
                            </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} sx={{ paddingTop: "15px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr" > {/* Türkçe dil ayarı */}
                            <DateRangePicker
                                slotProps={{ textField: isMobile ? { size: 'small' } : { size: 'medium' } }}
                                localeText={{ start: 'Başlangıç', end: 'Bitiş' }}
                                value={dateRange}
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Paper>
                {loading ? (
                    <Loading />
                ) : (
                    isSmallScreen ?
                        sortedData.length <= 0 ?
                            <>
                                <Grid container sx={{ justifyContent: "center", paddingTop: "50px" }}>
                                    Uygun kupon bulunamadı.
                                </Grid>
                                <Grid container sx={{ justifyContent: "center", paddingTop: "50px" }}>
                                    <Logo />
                                </Grid>

                            </>
                            :
                            sortedData.map((row) => (
                                <Paper key={row.id} elevation={3} style={{ padding: '10px', marginBottom: '20px', borderLeft: row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9|| row.type_id === 18 ? "solid 5px #ff0052" : "solid 5px #007d49"  }}>
                                    <Grid container sx={{ borderBottom: "solid 1px" }}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'left' }}>
                                            <Typography gutterBottom sx={{ fontSize: "0.8rem" }}>
                                                İŞLEM:<br /> <Grid> {row.type}</Grid>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'right' }}>
                                            <Typography gutterBottom sx={{ fontSize: "0.8rem" }}>
                                                TARİH:<br /> {row.transaction_date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ justifyContent: "center" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography gutterBottom             onClick={() => {
                if (row.status === "1" || row.status === "0") {
                    handleClick(row.event_id);
                }
            }} sx={{ color: "#fff", fontSize: "0.9rem",cursor:"pointer" }}>
                                               ID:{row.id} | TUTAR:<span style={{maxWidth:"90px", color: row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9 || row.type_id === 19? "#ff0052" : "#007d49" }}> {row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9 || row.type_id === 18 ? -1*row.amount:row.amount}</span>
                                            </Typography>
                                            <Typography gutterBottom sx={{ fontSize: "0.75rem" }}>
                                            İŞLEMİ YAPAN: {row.op_id}  | SON BAKİYE:{row.last_amount}  |  KOMİSYON: {row.comission}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))
                        :
                        <TableContainer component={Paper}>
                                                    {sortedData.length <= 0 ?
                            <>
                                <Grid container sx={{ justifyContent: "center", paddingTop: "50px" }}>
                                    Uygun kupon bulunamadı.
                                </Grid>
                                <Grid container sx={{ justifyContent: "center", paddingTop: "50px",paddingBottom: "50px" }}>
                                    <Logo />
                                </Grid>

                            </>
                            :
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'id'}
                                                direction={orderBy === 'id' ? order : 'asc'}
                                                onClick={() => handleSort('id')}
                                                sx={{ color: 'white' }}
                                            >
                                               ID
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'type_id'}
                                                direction={orderBy === 'type_id' ? order : 'asc'}
                                                onClick={() => handleSort('type_id')}
                                                sx={{ color: 'white' }}
                                            >
                                                İŞLEM
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{ color: 'white' }}
                                            >
                                                DETAY
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'amount'}
                                                direction={orderBy === 'amount' ? order : 'asc'}
                                                onClick={() => handleSort('amount')}
                                                sx={{ color: 'white' }}
                                                style={{maxWidth:"90px"}}
                                            >
                                                TUTAR
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'last_amount'}
                                                direction={orderBy === 'last_amount' ? order : 'asc'}
                                                onClick={() => handleSort('last_amount')}
                                                sx={{ color: 'white' }}
                                            >
                                                SON BAKİYE
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell style={{maxWidth:"90px"}}>
                                            <TableSortLabel
                                                active={orderBy === 'comission'}
                                                direction={orderBy === 'comission' ? order : 'asc'}
                                                onClick={() => handleSort('comission')}
                                                sx={{ color: 'white' }}
                                            >
                                                KOMİSYON
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'op_id'}
                                                direction={orderBy === 'op_id' ? order : 'asc'}
                                                onClick={() => handleSort('op_id')}
                                                sx={{ color: 'white' }}
                                            >
                                                İŞLEMİ YAPAN
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'transaction_date'}
                                                direction={orderBy === 'transaction_date' ? order : 'asc'}
                                                onClick={() => handleSort('transaction_date')}
                                                sx={{ color: 'white' }}
                                            >
                                                TARİH
                                            </TableSortLabel>
                                        </TableCell>
                                       
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                        {sortedData.map((row) => (
                                            <>
                                                <TableRow key={row.id} sx={{ borderLeft: row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9|| row.type_id === 18 ? "solid 5px #ff0052" : "solid 5px #007d49" }}>
                                                    <TableCell>{row.id}</TableCell>
                                                    <TableCell onClick={() => {
                if (row.status === "1" || row.status === "0") {
                    handleClick(row.event_id);
                }
            }}  style={{cursor:"pointer"}} >{row.type}</TableCell>
                                                        <TableCell style={{minWidth:"250px",fontSize:"0.7rem"}}>
                                                        {row?.coupon ? 
                                                            `${row?.event_name} | ${row?.odd_name} | ${row?.coupon?.type === 0 ? "BACK" : "LAY"} | ${row?.coupon?.odds} | ${row?.coupon?.stake}`
                                                            : ""
                                                        }
                                                        </TableCell>
                                                    <TableCell  style={{maxWidth:"90px", color: row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9||row.type_id === 18 ? "#ff0052" : "#007d49" }}>{row.type_id==3||row.type_id==7||row.type_id==14||row.type_id==9||row.type_id === 18 ?-1*row.amount:row.amount}</TableCell>
                                                    <TableCell> {row.last_amount}</TableCell>
                                                    <TableCell style={{maxWidth:"25px"}}> {row.comission&&row.comission+"%"}</TableCell>
                                                    <TableCell>{row.op_id}</TableCell>
                                                    <TableCell>{row.transaction_date}</TableCell>
                                                </TableRow>
                                            </>
                                       ))}
                                        
                                </TableBody>
                            </Table>
                            }
                        </TableContainer>
                )}
            </Grid>
        </Grid>
                        <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                        <Alert
                        onClose={handleClose}
                        color={status}
                        variant="filled"
                        sx={{ width: '100%',color:"#fff"}}
                        >
                        {statustext}  
                        </Alert>
                        </Snackbar>
                        <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Teklifi iptal edilecek, emin misiniz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bu işlemi gerçekleştirmek istediğinizden emin misiniz? İptal işlemi geri alınamaz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Hayır
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Evet
                    </Button>
                </DialogActions>
            </Dialog>
                        </>
    );
};

export default MyTransactions;
