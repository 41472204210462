import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Typography, ToggleButtonGroup,OutlinedInput,InputAdornment, ToggleButton, IconButton, Snackbar, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { apiRequest, apiGetRequest } from '../config';
import Loading from '../components/loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Logo from '../media/logosvg';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { useNavigate } from 'react-router-dom';

const MyTransactions = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [loading, setLoading] = useState(true);
    const [opensnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState("error");
    const [statustext, setStatustext] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
    const [datas, setFiltredDatas] = useState([]);
    const [statuscoup, setStatuscoup] = useState("all");
    const [profit, setProfit] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const[amounttry,setAmountTry]= useState();
    const [endpoint,setEndPoint]= useState();
    const [methodid,setMethodId]= useState();
    const [methodname,setMethodName]= useState();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1280);
            setIsMediumScreen(window.innerWidth > 1280 && window.innerWidth < 1800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {

        document.title = "Thexch | Bahislerim";


    }, []);
    useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem("authToken");
        apiGetRequest("/payment-methods", token)
            .then(response => {
                if (response) {
                    const method=response?.filter((item)=>item?.method_type=="1")
                    setData(method);
                    setLoading(false);

                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [dateRange]); // useEffect'in bağımlılıkları arasında statuscoup var, doğru şekilde yazılmış
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleChange = (event) => {
        setFilterText(event.target.value);
    };

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(columnId);
    };

    const filteredData = datas?.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const sortedData = filteredData.sort((a, b) => {
        if (order === 'desc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
        }
    });

    useEffect(() => {
        if (statuscoup == 2) {
            setFiltredDatas(data.filter(item => item.type_id == 8 || item.type_id == 1))
        } else if (statuscoup == 3) {
            setFiltredDatas(data.filter(item => item.type_id == 9 || item.type_id == 10))
        } else if (statuscoup == 4) {
            setFiltredDatas(data.filter(item => item.type_id == 14))
        } else if (statuscoup == 5) {
            setFiltredDatas(data.filter(item => item.type_id == 2 || item.type_id == 3 || item.type_id == 4))
            const total2And4 = data
                .filter(obj => obj.type_id === 2 || obj.type_id === 4)
                .reduce((sum, obj) => sum + Number(obj.amount), 0);

            const total3And14 = data
                .filter(obj => obj.type_id === 3 || obj.type_id === 14)
                .reduce((sum, obj) => sum + Number(obj.amount), 0);


            const difference = total2And4 - total3And14;
            setProfit(difference.toFixed(2))
        } else {
            setFiltredDatas(data)
        }

    }, [statuscoup, data])


    const handleChanges = (event, newAlignment) => {
        setStatuscoup(newAlignment);
    };
    const handleOpenDialog = (coupon_id) => {
        setCouponToDelete(coupon_id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCouponToDelete(null);
    };

    const handleConfirmDelete = () => {
        deleteCoup(couponToDelete);
        handleCloseDialog();
    };



    const handleDateChange = (newDateRange) => {
        // console.log(newDateRange)
        // dayjs.locale('tr')
        // newDateRange[0]?.$L = "tr"
        // newDateRange[1]?.$L = "tr"
        setDateRange(newDateRange);
    };
    const [expandedRows, setExpandedRows] = useState({});
    useEffect(() => {
        // Belirli z-index değerine sahip <div> öğelerini kaldır
        const removeSpecificDiv = () => {
            const divs = document.querySelectorAll('div');
            divs.forEach(div => {
                const style = div.getAttribute('style');
                if (style && style.includes('z-index: 100000;')) {
                    div.remove();
                }
            });
        };

        // removeSpecificDiv fonksiyonunu 500ms'de bir çalıştır
        const intervalId = setInterval(removeSpecificDiv, 500);

        // Bileşen kaldırıldığında interval'i temizle
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    const toggleExpand = (coupon_id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [coupon_id]: !prevExpandedRows[coupon_id]
        }));
    };
    const opendia = (status,name,id,endpoint)=>{
        setOpenDialog(status)
        setMethodName(name)
        setMethodId(id)
    }
    const gopay=()=>{
        const token = localStorage.getItem("authToken");
        const { protocol, hostname } = window.location;
        let params = { method_id:methodid, amount:amounttry, backurl:hostname };
        apiRequest("/api/payment/create-wallet", params, token)
            .then(response => {
                if (response?.url) {
                    window.open(response.url, '_blank');
                    handleCloseDialog();
                } else {
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
                }
            }).catch(error => {
                console.error('Error with API requests:', error);
                setOpenSnack(true);
                setStatus("error");
                setStatustext( "Hata Oluştu Lütfen Tekrar Deneyin.");
            });
        
    }
    const getStatus = (id) => {
        switch (id) {
            case "0":
                return "Teklifte"
            case "1":
                return "Eşleşmiş"
                break;
            case "2":
                return "Sonuçlanmış"
                break;
            case "3":
                return "İptal"
                break;
            case "4":
                return "Lapsed"
                break;
            case "5":
                return "Voided"
                break;
            default:
                break;
        }

    }
    const handleClick = (id) => {
        navigate(`/event/${id}`);
    };
    dayjs.locale('tr')
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%" }}>
                <Grid item xs={11} sm={11} md={8} lg={8}>
                    <Typography variant='h3' sx={{ fontWeight: "200", fontSize: isSmallScreen ? "2rem" : "3rem" }} >Para Yatırma</Typography>
                    <Paper elevation={3} style={{ padding: '20px', borderBottom: "solid" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={8} sx={{ marginRight: isMediumScreen && "45px" }}>
                                {"Yöntemler"}
                            </Grid>
                        </Grid>

                    </Paper>
                    <Paper elevation={3} style={{ marginBottom: '20px' }}>
    <Grid container sx={{ padding: "20px", alignItems: "center" }}>
      {data.map((item) => (
        <>
        <Grid
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            border: "solid 2px #454545",
            transition: "transform 0.3s, box-shadow 0.3s",
            cursor: "pointer",
            marginLeft:"5px",
            '&:hover': {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              border: "solid 2px "
            }
          }}
          item
          xs={3.8}
          sm={3.8}
          md={2.4}
          lg={2.4}
          onClick={()=>opendia(true,item.method_name,item.id,item.method_endpoint)}
        >
          <img
            src={`/image/${item.method_name}.png`}
            alt={item.method_name}
            style={{ width: '50%', height: 'auto', marginBottom: '8px', marginTop: "10px" }}
          />
          <div>
            {item.method_name}
          </div>
        </Grid>
                    </>
      ))}
    </Grid>
  </Paper>
                </Grid>
            </Grid>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    color={status}
                    variant="filled"
                    sx={{ width: '100%', color: "#fff" }}
                >
                    {statustext}
                </Alert>
            </Snackbar>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {methodname + " ile Yatırım"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <Typography  sx={{ mb: 2, textAlign: 'start',margin:"0px" }}>Yatırım Tutarı (TRY)</Typography>
        <OutlinedInput id="outlined-basic"sx={{width:"100%",marginBottom:"10px"}} type='number' onChange={(e)=>setAmountTry(e.target.value)} value={amounttry}  endAdornment={<InputAdornment position="end">TRY</InputAdornment>} variant="outlined" />
        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        İptal
                    </Button>
                    <Button onClick={gopay} variant="contained"  color="primary" autoFocus>
                        Ödeme Sayfası
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyTransactions;
