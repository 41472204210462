import { useState, useEffect } from 'react';
import { apiRequest, apiGetRequest } from '../config';

const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      apiRequest("/verify", { token }, token)
        .then(response => {
          if(response){
            setUser(response);
          }else{
            console.error('Oturum doğrulama hatası:', error);
            setUser(null);
            localStorage.removeItem("authToken");
          }
          
        })
        .catch(error => {
          console.error('Oturum doğrulama hatası:', error);
          setUser(null);
          localStorage.removeItem("authToken");
        });
    } else {
      setUser(null);
      localStorage.removeItem("authToken");
    }
  }, [localStorage.getItem("authToken")]);
  const verify = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const response = await apiRequest("/verify", { token }, token);
        if(response){
          setUser(response);
          return response.balance; // balance değeri döndürülüyor
        } else {
          console.error('Oturum doğrulama hatası:', error);
          setUser(null);
          localStorage.removeItem("authToken");
        }
      } catch (error) {
        console.error('Oturum doğrulama hatası:', error);
        setUser(null);
        localStorage.removeItem("authToken");
      }
    } else {
      setUser(null);
      localStorage.removeItem("authToken");
    }
  }
  const login = (userData) => {
    localStorage.setItem("authToken", userData.token); // Token yerel depolamaya ekleniyor
    setUser(userData);
  };

  const logout = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      apiGetRequest("/api/user/logout", token)
        .finally(() => {
          localStorage.removeItem("authToken");
          setUser(null);
        });
    }
  };

  const getUser = () => user;
  const isLoggedIn = () => user !== null;

  return { login, logout, getUser, isLoggedIn,verify };
};

export default useAuth;
