import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery, Button,Snackbar ,Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import LigMenu from '../components/leaguemenu';
import MobileSearch from '../components/mobilsearch';
import MatchOddsTable from '../components/sportbook/prematch';
import InPlayMatches from '../components/sportbook/in-play';
import MatchOddsTableDesktop from '../components/sportbook/prematchdektop';
import InPlayDesktop from '../components/sportbook/in-playdesktop';
import SportbookHeader from '../components/sportbook/sportbookheader';
import DesktopSearch from '../components/desktopsearch';
import BettingTab from '../components/rightmenu';
import QuickLinks from '../components/quicklink';
import useAuth from '../api/auth';
import { useLocation } from 'react-router-dom';

export default function MainPage({ refreshComponent, keys,setBalance }) {
  const auth = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeleague, setActiveLeague] = useState();
  const [activeleaguename, setActiveLeagueName] = useState();
  const [status,setStatus] = useState("error");
  const [opensnack, setOpenSnack] = useState(false);
  const [statustext,setStatustext] = useState("");
  const [activeCountry, setActiveCountry] = useState();
  const [matchcount, setmatchcount] = useState();
  const [recheck, setRecheck] = useState(1);
  const [livematchcount, setlivematchcount] = useState(true);
  const [log, setLog] = useState();
  const isEvent = false;
  useEffect(() => {
      
    document.title = "Exchange | Thexch";


  }, []);
  useEffect(() => {
    setLog(auth.isLoggedIn())
  }, [auth])
  
  const toggleDrawer = (open) => (event) => {
    setIsDrawerOpen(open);
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

const handleChange = (event) => {
    setFilterText(event.target.value);
};
  const setLeague = (id, name) => {
    if (id === activeleague) {
      setActiveLeague();
      setActiveLeagueName();
    } else {
      setActiveLeague(id);
      setActiveLeagueName(name);
      setIsDrawerOpen(false);
    }
    setmatchcount(true);
  };

  const location = useLocation();

  useEffect(() => {
    const leagueid = location?.state?.leagueid;
    if (leagueid) {
      setActiveLeague(leagueid);
      setActiveLeagueName(location?.state?.name)
    }
  }, [location])

  useEffect(() => {
    const countryid = location?.state?.countryid;
    if (countryid) {
      setActiveCountry(countryid);
    }
  }, [location])
  
  const clearAll = () => {
    setActiveLeague();
    setActiveLeagueName();
    setmatchcount(true);
    setlivematchcount(true);
  };

  const isMobile = useMediaQuery('(max-width:1280px)');

  return (
    <>
    <Grid container key={keys}>
      {!isMobile ?
        <>
          <Grid item xs={1} sm={1} md={2} lg={1.7}>
            <LigMenu isDrawerOpen={isDrawerOpen} activeleague={activeleague} activeCountry={activeCountry} toggleDrawer={toggleDrawer} setLeague={setLeague} />
          </Grid>
          <Grid item xs={10} sm={10} md={8} lg={8}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <SportbookHeader
                      key={activeleague + "head"}
                      activeleague={activeleague}
                      activeCountry={activeCountry}
                      defaulthead={"Exchange"}
                      mobile={false}
                      activeleaguename={activeleaguename}
                    />
                  </Grid>
                  {activeleague &&
                    <Grid item>
                      <Button sx={{ marginTop: "16px", bgcolor: "#272727", fontSize: "0.6rem" }} onClick={() => clearAll()}>
                        <CloseIcon style={{ fontSize: "0.8rem" }} />{"Filtreyi Kaldır"}
                      </Button>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5}>
                <DesktopSearch isDrawerOpen={isDrawerOpen} activeleague={activeleague} toggleDrawer={toggleDrawer} setLeague={setLeague} />
              </Grid>
            </Grid>

            {matchcount === false && livematchcount === false ?
              <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "5px", padding: "20px", backgroundColor: "#272727" }}>
                <Typography variant={"h6"}>Uygun Maç Bulunamadı</Typography>
              </Grid>
              :
              <>
                {livematchcount && <InPlayDesktop key={activeleague + "in"} activeCountry={activeCountry} recheck={recheck+1} setRecheck={setRecheck} activeleague={activeleague} setlivematchcount={setlivematchcount} setBalance={setBalance} counts={10} />}
                <MatchOddsTableDesktop key={activeleague} activeleague={activeleague} recheck={recheck+1} setRecheck={setRecheck} activeCountry={activeCountry} setmatchcount={setmatchcount}  setBalance={setBalance} counts={10}/>
              </>
            }
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2.3}>
            <QuickLinks />
            {log ? (<BettingTab key={"btab"} recheck={recheck+1} setBalance={setBalance} setRecheck={setRecheck} setStatus={setStatus} setOpenSnack={setOpenSnack} setStatustext={setStatustext}/>) : ""}
          </Grid>
        </>
        :
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MobileSearch isDrawerOpen={isDrawerOpen} activeleague={activeleague} toggleDrawer={toggleDrawer} setLeague={setLeague} isEvent={isEvent} />
          <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <SportbookHeader
                      key={activeleague + "head"}
                      activeleague={activeleague}
                      activeCountry={activeCountry}
                      defaulthead={"Exchange"}
                      mobile={true}
                      activeleaguename={activeleaguename}
                    />
                  </Grid>
                  {activeleague &&
                    <Grid item>
                      <Button sx={{ marginTop: "16px", bgcolor: "#272727", fontSize: "0.6rem" }} onClick={() => clearAll()}>
                        <CloseIcon style={{ fontSize: "0.8rem" }} />{"Filtreyi Kaldır"}
                      </Button>
                    </Grid>
                  }
                </Grid>
          {matchcount === false && livematchcount === false ?
            <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "20px", marginTop: "5px", backgroundColor: "#272727" }}>
              <Typography variant={"h6"}>Uygun Maç Bulunamadı</Typography>
            </Grid>
            :
            <>
              <InPlayMatches key={activeleague + "in"} activeleague={activeleague} setlivematchcount={setlivematchcount} setBalance={setBalance} counts={10}/>
              <MatchOddsTable key={activeleague} activeleague={activeleague} setmatchcount={setmatchcount} setBalance={setBalance} counts={10}/>
            </>
          }
        </Grid>
      }

    </Grid>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
            <Alert
            onClose={handleClose}
            color={status}
            variant="filled"
            sx={{ width: '100%',color:"#fff"}}
            >
            {statustext}  
            </Alert>
            </Snackbar></>
  );
}
