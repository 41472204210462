import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MyWaitingBets from '../pages/waitingcouponright';
import useAuth from '../api/auth';
import Coupon from './coupon';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function BettingTab({recheck,setRecheck,getnow,
  handleNavigate,selectedodddetail, setStatustext, setOpenSnack, setStatus, reselectodd,
  setselectedodddetail, setConfirmedodd, confirmedodd, expandedMatchId, setExpandedMatchId,matchid,setBalance,setMatchRisk,matchrisk
}) {
  const auth = useAuth();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const isEventPage = () => {
    return /\/event\/(\d+)/.test(location.pathname);
  };

  useEffect(() => {
    if (isEventPage()) {
      handleChangeIndex(1);
    } else {
      handleChangeIndex(0);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "95%", paddingLeft: "15px",overflow:"hidden", paddingTop: "14px" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {auth.isLoggedIn() && <Tab label="Kuponlarım" {...a11yProps(isEventPage() ? 1 : 0)} />}
          {isEventPage() && <Tab label="Bahis" {...a11yProps(0)} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {auth.isLoggedIn() && (
          <Box sx={{ width: "100%",overflow:"hidden" }}>
            <MyWaitingBets recheck={recheck} getnow={getnow} setMatchRisk={setMatchRisk} matchrisk={matchrisk} matchid={matchid} setRecheck={setRecheck} setOpenSnack={setOpenSnack} setStatus={setStatus} setStatustext={setStatustext}/>
          </Box>
        )}
        {isEventPage() && (
          <Box sx={{ width: "100%" }}>
            <Coupon
            handleNavigate={handleNavigate}
              selectedodddetail={selectedodddetail}
              setOpenSnack={setOpenSnack}
              setStatus={setStatus}
              setStatustext={setStatustext}
              reselectodd={reselectodd}
              confirmedodd={confirmedodd}
              setConfirmedodd={setConfirmedodd}
              setselectedodddetail={setselectedodddetail}
              expandedMatchId={expandedMatchId}
              setExpandedMatchId={setExpandedMatchId}
              recheck={recheck} 
              setRecheck={setRecheck}
              setBalance={setBalance}
              handleChangeIndex={handleChangeIndex}
              setMatchRisk={setMatchRisk} 
              matchrisk={matchrisk}
            />
          </Box>
        )}
      </SwipeableViews>
    </Box>
  );
}
