import React, { useState, useEffect,useRef } from 'react';
import { Button, TextField, Paper, Grid, IconButton, useMediaQuery, Typography, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiRequest } from '../../config';
import useAuth from '../../api/auth';
function BetPlacingComponent({ type,setRecheck,riskcheck,setOpen,recheck,setStatus, setStatustext, price, oddname, oddid, marketname, matchname, matchid, marketid, setExpandedMatchId, setselectedodddetail,setBalance,selectedodddetail,index }) {
  const [stake, setStake] = useState('');
  const auth = useAuth();
  const [odds, setOdds] = useState(price);
  const isMobile = useMediaQuery('(max-width:1280px)');
  const [confirm,setConfirm]=useState(false);
  const [rocheck,setRocheck]=useState(false);
  const oddsInputRef = useRef(null);
  const [activeField, setActiveField] = useState('stake');
  const [couponloading, setCouponloading] = React.useState(false);
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
  const roundingRules = [
    { start:1,limit: 2, step: 0.01 },
    { start:2,limit: 3, step: 0.02 },
    { start:3,limit: 4, step: 0.05 },
    { start:4,limit: 6, step: 0.1 },
    { start:6,limit: 10, step: 0.2 },
    { start:10,limit: 20, step: 0.5 },
    { start:20,limit: 30, step: 1.0 },
    { start:30,limit: 50, step: 2.0 },
    { start:50,limit: 100, step: 5.0 },
    { start:100,limit: 1000, step: 10.0 }
  ];
  const adjustPrice = (price) => {
    const numericPrice = Number(price.replace(/,/g, '.'));
    if (numericPrice > 1 && numericPrice <= 2) {
      // 1-2 arasında virgül olarak ,01 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.01) * 0.01);
  } else if (numericPrice > 2 && numericPrice <= 3) {
      // 2-3 arasında virgül olarak ,02 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.02) * 0.02);
  } else if (numericPrice > 3 && numericPrice <= 4) {
      // 3-4 arasında virgül olarak ,05 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.05) * 0.05);
  } else if (numericPrice > 4 && numericPrice <= 6) {
      // 4-6 arasında virgül olarak ,1 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.1) * 0.1);
  } else if (numericPrice > 6 && numericPrice <= 10) {
      // 6-10 arasında virgül olarak ,2 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.2) * 0.2);
  } else if (numericPrice > 10 && numericPrice <= 20) {
      // 10-20 arasında virgül olarak ,5 olabilir
      return Math.floor(numericPrice) + (Math.round((numericPrice - Math.floor(numericPrice)) / 0.5) * 0.5);
  } else if (numericPrice > 20 && numericPrice <= 30) {
      // 20-30 arasında tam sayı olacak
      return Math.round(numericPrice);
  } else if (numericPrice > 30 && numericPrice <= 50) {
      // 30-50 arasında çift sayı
      return 2 * Math.round(numericPrice / 2);
  } else if (numericPrice > 50 && numericPrice <= 100) {
      // 50-100 arasında 5'erli tam sayı
      return 5 * Math.round(numericPrice / 5);
  } else if (numericPrice > 100 && numericPrice <= 1000) {
      // 100-1000 arasında 10'ar lı tam sayı
      return 10 * Math.round(numericPrice / 10);
  }
  
  return numericPrice; // Eğer belirtilen aralıklar dışında ise değişiklik yapılmaz
};

useEffect(() => {
  if (Array.isArray(selectedodddetail) && selectedodddetail[index]) {
      const newSelectedOddDetail = [...selectedodddetail];
      
      if (newSelectedOddDetail[index].amount !== stake) {
          newSelectedOddDetail[index] = {
              ...newSelectedOddDetail[index],
              amount: stake
          };
          setselectedodddetail(newSelectedOddDetail);
      }
  }
}, [stake, index]);

const getsteprule=(value)=>{
  for (let rule of roundingRules) {
    if (value >= rule.start && value < rule.limit) {
      return rule.step;
    }
  }
  return null;
}

const handleIncrement = () => {


      setOdds(parseFloat(adjustPrice(String((Number(odds)+Number(getsteprule(odds)))))).toFixed(2)); 
  
};

const handleDecrement = () => {

    setOdds(parseFloat(adjustPrice(String((Number(odds)-Number(getsteprule(odds)))))).toFixed(2)); 
  
};
  const controlCoup = ()=>{
    setConfirm(true)
    setOdds((prev) => parseFloat(adjustPrice(String(prev)).toFixed(2)))
  }
  useEffect(() => {
    setOdds(price);
  }, [price]);

  const handleKey = (key) => {
    if (activeField === 'stake') {
      setStake((prev) => prev + key);
    } else if (activeField === 'odds') {
      oddsInputRef.current.focus();
      setOdds((prev) => String(prev) + key);
    }
  };

  const handleBackspace = () => {
    if (activeField === 'stake') {
      setStake((prev) => prev.slice(0, -1));
    } else if (activeField === 'odds') {
      oddsInputRef.current.focus();
      setOdds((prev) => String(prev).slice(0, -1)); 
    }
  };

  const handleOddsChange = (event) => {
    const input = event.target.value;
    setConfirm(false)
    setOdds(input);
  };

 
  const sendCoupon = () => {
    setCouponloading(true);
    const token = localStorage.getItem("authToken");
    let params = {
      description: {
        "event_id": matchid,
        "market_id": marketid,
        "odd_id": oddid,
      }, type: type, odds: odds, stake: stake
    };
    apiRequest("/api/coupon/createcoupon", params, token)
      .then(response => {
        if (response?.success) {
          setOpen(true);
          setCouponloading(false);
          setStatus(response?.status == 1 ? "success" : "warning");
          setStatustext(response?.status == 1 ? "Bahisiniz oluşturuldu ve EŞLEŞTİ." : "Bahisiniz oluşturuldu ve TEKLİFTE.");
          setselectedodddetail([]);
          setExpandedMatchId([]);
          if (Array.isArray(selectedodddetail) && selectedodddetail[index]) {
          riskcheck();
          }
        } else {
          setOpen(true);
          setCouponloading(false);
          setStatus("error");
          if(response?.message){
            setStatustext(response.message)
          }else{
            setStatustext("Bahisiniz oluşturulamadı.Lütfen tekrar deneyin.");
          }
        }
  
        const updateBalance = async () => {
      const newBalance = await auth.verify();

          if (newBalance !== null) {
           
            setBalance(newBalance);
          }
        };
        updateBalance();
      })
  };
 
  return (
    <>
      <Paper elevation={3} sx={{ p: 2, borderRadius: '8px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container spacing={0} alignItems="center">
        <Grid item xs={3} sm={3} md={0.8} lg={0.8}>
          <Typography sx={{bgcolor: type == "BACK" ? '#a6d8ff' : '#fac9d1', color: "#000",textAlign:"center",borderRadius:"5px"}}>
          {type}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={11.2} lg={11.2} sx={{paddingTop:"1px",paddingLeft:"3px"}}>
          {  matchname + " - " + marketname + "/" + oddname}
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} sm={8} md={3} lg={3}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3.1} sm={3.1} md={3.5} lg={3.5}>
              <Button variant="outlined" onClick={handleDecrement} sx={{padding:"6.5px 14px",minWidth:"54px",width:"100%"}} ><RemoveIcon /></Button>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <TextField
                size="small"
                value={odds}
                ref={oddsInputRef}
                key={"oddinput"}
                onChange={handleOddsChange}
                onBlur={()=>handleKey(" ")}
                onFocus={() => setActiveField('odds')}
                inputProps={{  style: { textAlign: 'center' } }}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item xs={3.1} sm={3.1} md={3.5} lg={3.5}>
              <Button variant="outlined" onClick={handleIncrement} sx={{padding:"6.5px 14px",minWidth:"54px",width:"100%"}} ><AddIcon /></Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={3} lg={3}>
          <TextField
            fullWidth
            type="text"
            label="Tutar"
            value={stake}
            onClick={() => setActiveField('stake')}
            onChange={(e) => {setStake(e.target.value), setConfirm(false)}}
            variant="outlined"
            size="small"
            InputProps={{
               style: { textAlign: 'center' }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
        <Grid container alignItems="center" >
            <Grid item xs={8} sm={8} md={8} lg={12}>
            <Grid container alignItems="start" >
            <Grid item xs={6} sm={6} md={6} lg={6}>
            <Button
  variant="contained"
  onClick={()=>{confirm?sendCoupon():controlCoup()}}
  sx={{
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    padding: "5px",
    bgcolor: type == "BACK" ? '#a6d8ff' : '#fac9d1',
    color: "#000"
  }}
  disabled={!stake?true:false}
>
  {couponloading ? (
    <CircularProgress size={24} sx={{color:"#000"}} />
  ) : (
    <div>
      {confirm?"Onayla":"Bahis"}
      {stake && (
        <div style={{ fontSize: "10px", marginLeft: "5px",marginTop:"-8px", width: "100%" }}>
          {type == "BACK" ? 'Profit ' : 'Liability '}{((odds - 1) * stake).toFixed(2)}
        </div>
      )}
    </div>
  )}
</Button>
</Grid>

<Grid item xs={6} sm={6} md={6} lg={6}>
            <Button variant="text" onClick={() => { setExpandedMatchId([]);setselectedodddetail([]); }}>Cancel</Button>
          </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginTop:"10px"}}>
            <Grid container alignItems="center" spacing={1}>
          {keys.map((key) => (
            <Grid item xs={2} sm={2} md={2} lg={2} key={key}>
              <Button type="button" variant="outlined" sx={{padding:"6.5px 14px",minWidth:"54px",width:"100%"}} onClick={() => handleKey(key)}>{key}</Button>
            </Grid>
          ))}
          <Grid item>
            <IconButton onClick={handleBackspace}>
              <KeyboardBackspaceIcon />
            </IconButton>
          </Grid>
          </Grid>
        </Grid>
      )}
    </Paper >
    </>
  );
}

export default BetPlacingComponent;
