import React, { Suspense } from 'react';
import { useState,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PreMatch from './pages/PreMatch';
import MainPage from './pages/MainPage';
import Header from './components/header';
import CircularProgress from '@mui/material/CircularProgress'; // Dönen top bileşeni
import Loading from './components/loading';
import Event from './pages/event';
import MyProfile from './pages/my-profile';
import useAuth from './api/auth';
import LivePage from './pages/Live';
import MyTransactions from './pages/my-transactions';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
const Mybets = React.lazy(() => import('./pages/my-bets'));

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  const auth = useAuth();
  const [keys, setKey] = useState(0);
  const [balance, setBalance] = useState(auth.getUser()?.balance);
  useEffect(() => {
    setBalance(auth.getUser()?.balance)
  }, [auth.getUser()?.balance])
  

function refreshComponent() {
  setKey(prevKey => prevKey + 1);
}
  return (
    <Router>
      <Header refreshComponent={refreshComponent} balance={balance} setBalance={setBalance}/>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/live" element={<LivePage refreshComponent={refreshComponent} keys={keys}  setBalance={setBalance}/>} />
          <Route path="/my-bets" element={<Mybets  setBalance={setBalance}/>} />
          <Route path="/my-transactions" element={<MyTransactions  setBalance={setBalance}/>} />
          <Route path="/deposit" element={<Deposit  setBalance={setBalance}/>} />
          <Route path="/withdraw" element={<Withdraw  setBalance={setBalance}/>} />
          <Route path="/" element={< MainPage refreshComponent={refreshComponent} keys={keys}  setBalance={setBalance}/>} />
          <Route path="/pre-match" element={<PreMatch  refreshComponent={refreshComponent} keys={keys}  setBalance={setBalance}/>} />
          <Route path="/event/:id" element={<Event setBalance={setBalance} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
