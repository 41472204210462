import React from 'react';
import {Typography,Button} from '@mui/material';
const SportbookHeader = ({activeleague,mobile,activeleaguename,defaulthead}) => {
    if(activeleague){

        return (
            activeleague &&(
            mobile?
            <Typography variant={"h6"} sx={{marginTop:"15px",marginLeft:"10px",fontWeight:"500"}}>{activeleaguename}</Typography>
            :
            <Typography variant={"h5"} sx={{marginTop:"15px",fontWeight:"500"}}>{activeleaguename}</Typography>)
          
        );
    } else {
        return  (
            !mobile&&
            <Typography variant={"h5"} sx={{marginTop:"15px",fontWeight:"500"}}>{defaulthead}</Typography>
        );
    }
};

export default SportbookHeader;
